import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
    paddingBottom: '30px',
  },
  icon: {
    cursor: 'pointer',
  },
  week: {
    padding: '0rem 1rem',
    textTransform: 'capitalize',
  },
  mode: {
    paddingRight: '1rem',
  },
  input: {
    paddingRight: '1em',
    width: '10em',
  },
  menuItem: {
    color: '#0188CE',
  },
}));

export default useStyles;
