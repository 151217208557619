import { gql } from '@apollo/client';
import { GraphqlQuery } from '../config/apolloClient';

const PARTICIPATION = gql`
  query allByCampus($id: Int!) {
    userParticipation(id: $id) {
      arrival
      departure
      hotelRoomNumber
      hotelName
      isHalfBoard
      session {
        id
        name
        startDate
        endDate
      }
      user {
        id
        firstname
        lastname
        email
        cp
        octopusUserId
        userAttributes {
          id
          name
          value
        }
      }
    }
  }
`;

export default class Hosting {
  static allByCampus(id) {
    return GraphqlQuery(PARTICIPATION, { id })
      .then((result) => result.userParticipation)
      .catch((e) => e);
  }
}
