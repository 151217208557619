import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FMEUR } from 'config/constants/Roles';
import PrivateRoute from './PrivateRoute';
import Unauthorized from '../Unauthorized/Unauthorized';

function AdminRoute({ children }) {
  const user = useSelector((state) => state.user);
  const isUserAdmin = user?.role?.name === FMEUR;

  return isUserAdmin ? (
    <PrivateRoute>{children}</PrivateRoute>
  ) : (
    <Unauthorized />
  );
}

AdminRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AdminRoute;
