import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  center: {
    flexGrow: 1,
    marginTop: '0.5em',
    padding: '1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  title: {
    width: '95%',
    fontWeight: 'bold',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: 1,
  },
  description: {
    letterSpacing: '0px',
    color: '#000000',
    textAlign: 'left',
    paddingRight: '0%',
    [theme.breakpoints.up('md')]: {
      paddingRight: '5%',
    },
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2em',
      marginBottom: '2em',
    },
  },
  tabButton: {
    opacity: '50%',
  },
}));

export default useStyles;
