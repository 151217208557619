import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './Popup.style';

function Popup({
  title,
  description,
  buttonText,
  isClosable,
  buttonTextClose,
  handleClose,
  handleAction,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      width="sm"
      fullWidth
    >
      <DialogTitle
        id="form-dialog-title"
        className={classes.titleContainer}
        sx={{
          paddingRight: 0,
        }}
      >
        <Typography
          variant="h5"
          className={classes.title}
          sx={{
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        {isClosable && (
          <IconButton
            onClick={handleClose}
            sx={{ color: '#4D4F53', marginRight: '0.3em' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="#000" textAlign="justify">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonTextClose?.length > 0 && (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClose}
            sx={{
              padding: '0.5em 1em',
              margin: '0 0.5em 0.3em 0',
            }}
          >
            {buttonTextClose}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={handleAction}
          sx={{
            padding: '0.5em 1em',
            margin: '0 0.5em 0.3em 0',
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Popup.defaultProps = {
  handleClose: () => null,
  buttonTextClose: '',
  isClosable: true,
};

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isClosable: PropTypes.bool,
  handleClose: PropTypes.func,
  buttonTextClose: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
};

export default Popup;
