import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  inputsDesktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  inputsMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  buttons: {
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  switchButton: {
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '400ms',
      '&.Mui-checked': {
        transform: 'translateX(23px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#0188CE',
          opacity: 1,
          border: 0,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#0188CE',
        border: '6px solid #fff',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#B7B7B7',
      opacity: 1,
    },
  },
}));

export default useStyles;
