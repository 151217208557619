import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TRAINEE, TRAINER } from 'config/constants/Roles';
import PrivateRoute from './PrivateRoute';
import Unauthorized from '../Unauthorized/Unauthorized';

function FormationRoute({ children }) {
  const user = useSelector((state) => state.user);
  const isUserTraineer =
    user?.role?.name === TRAINEE || user?.role?.name === TRAINER;

  return isUserTraineer ? (
    <PrivateRoute>{children}</PrivateRoute>
  ) : (
    <Unauthorized />
  );
}

FormationRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FormationRoute;
