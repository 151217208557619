/* eslint-disable no-restricted-syntax */
export default class UserModel {
  constructor() {
    this.role = null;
    this.id = null;
    this.octopusUserId = null;
    this.email = null;
    this.firstname = null;
    this.lastname = null;
    this.cp = null;
    this.institution = null;
    this.campus = null;
    this.currentParticipation = null;
    this.photoUrl = null;
    this.phone = null;
  }

  isAuthenticated() {
    return !!this.role?.name;
  }

  mapFromUserClient(attributeValues) {
    if (!attributeValues) throw new Error('attributeValues is not defined');
    for (const attr of Object.keys(this)) {
      this[attr] = attributeValues[attr];
    }
    attributeValues?.userAttributes?.forEach((userAttribute) => {
      this[userAttribute?.name] = userAttribute?.value;
    });
  }
}
