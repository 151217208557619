import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  center: {
    flexGrow: 1,
    marginTop: '0.5em',
    padding: '1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  tabCard: {
    marginLeft: '20px',
  },
  titles: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    fontFamily: 'Avenir',
  },
}));

export default useStyles;
