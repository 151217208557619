import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    color: '#3C3C3B',
    paddingTop: '1em',
  },
}));

export default useStyles;
