import {
  loginRequest,
  MicrosoftClient,
  MicrosoftAuthConfig,
} from '../config/MicrosoftAuthConfig';

function RequestAccessToken(processToken) {
  const account = MicrosoftClient.getAllAccounts()[0];

  const request = {
    ...loginRequest,
    ...MicrosoftAuthConfig,
    account,
  };

  return MicrosoftClient.acquireTokenSilent(request)
    .then(processToken)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      return MicrosoftClient.acquireTokenRedirect(request).then(processToken);
    });
}

export default RequestAccessToken;
