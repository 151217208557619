import React from 'react';
import { Fab } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './FloatingActionButton.style';

export default function FloatingActionButton({ children }) {
  return (
    <Fab color="secondary" style={styles.container}>
      {children}
    </Fab>
  );
}

FloatingActionButton.propTypes = {
  children: PropTypes.element.isRequired,
};
