import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useStyles from './MyProfile.style';
import ProfileInformation from '../../components/ProfileInformation/ProfileInformation';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';

export default function MyProfile() {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const userFullName = `${user?.firstname} ${user?.lastname}`;

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10} className={classes.headerContainer}>
            <ProfilePicture
              userFullName={userFullName}
              size={{ width: '120px', height: '120px', fontSize: '40px' }}
            />
            <Typography variant="h5" sx={{ fontWeight: 700, mt: '0.5em' }}>
              {t('myProfile.title').toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <ProfileInformation />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
