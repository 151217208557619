import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TabCard from 'components/TabCard/TabCard';
import { useTranslation } from 'react-i18next';
import UseLocationMenu from 'components/UseLocationMenu/UseLocationMenu';
import PropTypes from 'prop-types';
import useStyles from './InterventionRequestDesktop.style';
import CreateRequestDesktop from './CreateRequestDesktop/CreateRequestDesktop';
import MyRequestDesktop from './MyRequestDesktop/MyRequestDesktop';

function InterventionRequestDesktop(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sendAnomalies, setSendAnomalies } = props;

  const makeTitle = (title) => (
    <Typography sx={{ fontWeight: '700' }} className={classes.titles}>
      {title}
    </Typography>
  );

  const contents = [
    {
      title: makeTitle(t('interventionRequest.interventionRequestTabTitle')),
      content: <CreateRequestDesktop {...props} />,
    },
    {
      title: makeTitle(t('interventionRequest.myRequestsTabTitle')),
      content: <MyRequestDesktop {...props} />,
    },
  ];
  return (
    <Box className={classes.center} data-testid="irDesktop">
      <Grid container spacing={2} className={classes.background}>
        <UseLocationMenu />
      </Grid>
      <Grid className={classes.tabCard}>
        <TabCard
          contents={contents}
          TabSelect={sendAnomalies}
          setTabSelect={setSendAnomalies}
        />
      </Grid>
    </Box>
  );
}
InterventionRequestDesktop.propTypes = {
  sendAnomalies: PropTypes.number.isRequired,
  setSendAnomalies: PropTypes.func.isRequired,
};

export default InterventionRequestDesktop;
