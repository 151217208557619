import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListItemText, ListItemIcon, ListItem } from '@mui/material';
import {
  HomeOutlined,
  Event,
  SchoolOutlined,
  ForumOutlined,
  HelpOutline,
} from '@mui/icons-material';
import {
  CAMPUS_INFORMATION,
  GLOBAL_FORMATION,
  AGENDA,
  HOME,
} from '../RoutesConstants';
import { OfficeBuildingMarker, LogoSNCF } from '../../assets';
import { Drawer, DrawerHeader } from './Drawer';
import useStyles from './LeftMenu.style';

export default function LeftMenu({ open, setOpen }) {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [variant, setVariant] = useState('permanent');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const iconList = [
    { icon: <HomeOutlined />, label: t('leftMenu.homePage'), url: '/accueil' },
    { icon: <Event />, label: t('leftMenu.agenda'), url: '/agenda' },
    {
      icon: <OfficeBuildingMarker />,
      label: t('leftMenu.campus_information'),
      url: '/information-campus',
    },
    {
      icon: <SchoolOutlined />,
      label: t('leftMenu.formation'),
      url: '/formation',
    },
    {
      icon: <ForumOutlined />,
      label: t('leftMenu.forum'),
      url: '/en-construction',
    },
    {
      icon: <HelpOutline />,
      label: t('leftMenu.help'),
      url: '/en-construction',
    },
  ];

  useEffect(() => {
    const widthDOM =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (widthDOM < 600) {
      setVariant('temporary');
    }
    return 'permanent';
  }, []);

  useEffect(() => {
    const address = location.pathname.split('/')[1];
    switch (address) {
      case HOME:
        setSelectedIndex(0);
        break;
      case AGENDA:
        setSelectedIndex(1);
        break;
      case CAMPUS_INFORMATION:
        setSelectedIndex(2);
        break;
      case GLOBAL_FORMATION:
        setSelectedIndex(3);
        break;
      default:
        setSelectedIndex(null);
        break;
    }
  }, [location]);

  const classNameDrawer = () => {
    if (location.pathname === '/communiquer-son-arrivee') {
      return classes.drawerInvisible;
    }
    return open ? classes.drawerOn : classes.drawer;
  };

  return (
    <>
      <Drawer
        variant={variant}
        BackdropProps={{ invisible: true }}
        open={open}
        className={classNameDrawer()}
        onClose={handleDrawerClose}
      >
        <DrawerHeader className={classes.drawerHeader} />

        <button
          type="button"
          className={open ? classes.buttonLogoOn : classes.buttonLogo}
        >
          <img src={LogoSNCF} alt="Logo SNCF" className={classes.imageLogo} />
        </button>

        <List
          onMouseOver={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          className={classes.list}
        >
          {iconList.map((item, index) => {
            return (
              <ListItem
                button
                key={item.label}
                style={index === 4 ? { marginTop: 'auto' } : null}
                onClick={() => {
                  setSelectedIndex(index);
                  navigate(item.url);
                  setOpen(false);
                }}
                selected={selectedIndex === index}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label.toUpperCase()} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
}

LeftMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
