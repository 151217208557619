import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  InfoOutlined,
  RestaurantOutlined,
  LocalHotel,
  BookmarksOutlined,
  PinDropOutlined,
  ExploreOutlined,
  NewspaperOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import IconLabelCard from '../../components/IconLabelCard/IconLabelCard';
import { AccountGroupOutline } from '../../assets';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './CampusInformation.style';
import { ACCOMMODATION } from '../../navigation/RoutesConstants';

function CampusInformation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const title = t('title_campus', { name: user?.campus?.name });
  const description = t('campus_information.description', {
    name: user?.campus?.name,
  });

  const svgIconFormat = () => {
    return (
      <AccountGroupOutline
        data-testid="organigrammeIcon"
        className={classes.svgIcon}
      />
    );
  };

  const iconList = [
    {
      icon: InfoOutlined,
      label: t('campus_information.useful_info'),
      url: '/en-construction',
    },
    {
      icon: RestaurantOutlined,
      label: t('common.catering'),
      url: '/en-construction',
    },
    {
      icon: LocalHotel,
      label: t('common.accommodation'),
      url: `/${ACCOMMODATION}`,
    },
    {
      icon: BookmarksOutlined,
      label: t('campus_information.booklet'),
      url: '/information-campus/livret-accueil',
    },
    {
      icon: PinDropOutlined,
      label: t('common.go_to_campus'),
      url: '/en-construction',
    },
    {
      icon: ExploreOutlined,
      label: t('common.circulate'),
      url: '/en-construction',
    },
    {
      icon: NewspaperOutlined,
      label: t('campus_information.news'),
      url: '/en-construction',
    },
    {
      icon: svgIconFormat,
      label: t('campus_information.organization'),
      url: '/en-construction',
    },
  ];

  return (
    <>
      <main>
        <Box className={classes.center}>
          <Grid container spacing={2} className={classes.background}>
            <UseLocationMenu />
            <Grid item xs={12} md={10}>
              <Grid container className={classes.leftContainer}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700 }}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container direction="row" className={classes.leftContainer}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    className={classes.description}
                    gutterBottom
                  >
                    {description}
                  </Typography>
                </Grid>
                <Grid container>
                  {iconList.map((item) => {
                    return (
                      <Grid key={item.label} item xs={6} md={3}>
                        <IconLabelCard
                          label={item.label}
                          link={item.url}
                          Icon={item.icon}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </main>
    </>
  );
}

export default CampusInformation;
