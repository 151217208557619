import { gql } from '@apollo/client';
import { GraphqlQuery } from '../config/apolloClient';

const USER_ITEMS = gql`
  query findItemsForOctopusUserIdAt(
    $octopusUserId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    findItemsForOctopusUserIdAt(
      octopusUserId: $octopusUserId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      subGroupNum
      startDate
      endDate
      trainers {
        firstname
        lastname
      }
      room {
        id
        name
        building
        seatsNumber
      }
    }
  }
`;

export default class Items {
  static findByExternalSiteId(octopusUserId, startDate, endDate) {
    return GraphqlQuery(USER_ITEMS, {
      octopusUserId,
      startDate,
      endDate,
    })
      .then((result) => result.findItemsForOctopusUserIdAt)
      .catch((e) => e);
  }
}
