import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';

import { Card, CardContent } from '@mui/material';
import useStyles from './TabCard.styles';

function TabCard({ defaultValue, contents, TabSelect, setTabSelect }) {
  const classes = useStyles();

  const [selected, setSelected] = useState(defaultValue);

  const handleOnChange = (event, newValue) => {
    setSelected(newValue);
    setTabSelect(newValue);
  };
  useEffect(() => setSelected(TabSelect), [TabSelect]);

  const titles = contents.map((content, index) => {
    if (!content.title) return null;
    const isSelected = index === selected;
    const { tabTitle, tabTitleActive } = classes;

    return (
      <TabUnstyled className={isSelected ? tabTitleActive : tabTitle}>
        {content.title}
      </TabUnstyled>
    );
  });

  const panelContents = contents.map((content, index) => {
    if (!content.content) return null;
    return <TabPanelUnstyled value={index}>{content.content}</TabPanelUnstyled>;
  });

  return (
    <TabsUnstyled
      defaultValue={defaultValue}
      onChange={handleOnChange}
      value={selected}
    >
      <TabsListUnstyled>{titles}</TabsListUnstyled>
      <Card className={classes.card}>
        <CardContent>{panelContents}</CardContent>
      </Card>
    </TabsUnstyled>
  );
}

TabCard.propTypes = {
  contents: PropTypes.arrayOf({
    title: PropTypes.string, // tabcontent
    tabContent: PropTypes.node, // tabContent is JSX valid element
  }),
  TabSelect: PropTypes.number,
  setTabSelect: PropTypes.func,
  defaultValue: PropTypes.number,
};

TabCard.defaultProps = {
  contents: [],
  TabSelect: 0,
  defaultValue: 0,
  setTabSelect: () => null,
};

export default TabCard;
