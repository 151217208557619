import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1, marginLeft: `calc(56px + 5px)` }}>
      <p>{t('not_found_page_message')}</p>
    </Box>
  );
}
