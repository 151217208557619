/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Today } from '@mui/icons-material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useSelector, useDispatch } from 'react-redux';
import { saveUserState } from 'redux/reducers/userReducer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HOME } from 'navigation/RoutesConstants';
import ValidationForm from 'utils/ValidationForm/ValidationForm';

import moment from 'moment';
import useStyles from './ProfileInformation.style';
import User from '../../services/user.service';
import './DatePicker.css';

export default function ProfileInformation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const MAX_DATE = moment('31/12/2049', 'DD/MM/YYYY');

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const formInputs = [
    {
      id: 1,
      label: t('profileInformation.name'),
      name: 'name',
      required: true,
      readOnly: true,
    },
    {
      id: 4,
      label: t('profileInformation.establishment'),
      name: 'institution',
      required: true,
      readOnly: true,
    },
    {
      id: 6,
      label: t('profileInformation.startDate'),
      name: 'startDate',
      required: true,
      type: 'date',
      readOnly: !checked,
    },
    {
      id: 2,
      label: t('profileInformation.CP'),
      required: true,
      name: 'cp',
      readOnly: true,
    },
    {
      id: 5,
      label: t('profileInformation.phoneNumber'),
      required: false,
      placeholder: 'XXXXXXXXXX',
      helperText: t('profileInformation.helperTextPhone'),
      type: 'text',
      pattern: '[0-9]*',
      name: 'phone',
    },
    {
      id: 7,
      label: t('profileInformation.endDate'),
      required: true,
      type: 'date',
      name: 'endDate',
      helperText: t('profileInformation.helperTextDate'),
      readOnly: !checked,
    },
    {
      id: 3,
      label: t('profileInformation.email'),
      required: true,
      type: 'email',
      name: 'email',
      readOnly: true,
    },
    {
      id: 8,
      label: t('profileInformation.switch'),
      type: 'switch',
      name: 'accomodation',
    },
  ];

  const daysLimitToFixPage = 5;

  useEffect(() => {
    const {
      lastname,
      firstname,
      email,
      cp,
      institution,
      currentParticipation,
    } = user;
    setValues({
      email,
      cp,
      name: `${lastname} ${firstname}`,
      startDate:
        user.currentParticipation.arrival ??
        new Date(currentParticipation?.session?.startDate),
      endDate:
        currentParticipation?.departure ??
        new Date(currentParticipation?.session?.endDate),
      institution: institution.name,
      isHalfBoard: user.currentParticipation.isHalfBoard
        ? user.currentParticipation.isHalfBoard
        : false,
    });
    if (user.currentParticipation.isHalfBoard !== null) {
      setChecked(!user.currentParticipation.isHalfBoard);
    } else {
      setChecked(true);
    }
  }, [user]);

  const handleChangeAccomodation = () => {
    if (checked) {
      setValues({
        endDate: moment(user.currentParticipation?.session?.endDate),
        startDate: moment(user.currentParticipation?.session?.startDate),
        isHalfBoard: true,
      });
    } else {
      setValues({
        endDate: moment(user.currentParticipation?.session?.endDate),
        startDate: moment(user.currentParticipation?.session?.startDate),
        isHalfBoard: false,
      });
    }
    setChecked(!checked);
  };

  const formatDateWithoutHours = (date = moment()) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const formatDate = (date = moment()) => {
    return moment(date).format();
  };

  const onSubmitForm = (e) => {
    setIsDisabled(true);
    e.preventDefault();
    const { phone, endDate, startDate, isHalfBoard } = values;

    const validateDate =
      moment(startDate) <= moment(endDate) &&
      formatDateWithoutHours(startDate) >= formatDateWithoutHours() &&
      moment(endDate) <= MAX_DATE;
    if (validateDate) {
      ValidationForm(setErrors, () => {
        const { currentParticipation, id, octopusUserId } = user;
        User.itsArrival({
          id,
          octopusUserId,
          phone: phone ?? user.phone,
          ending: endDate,
          arrival: startDate,
          sessionId: currentParticipation?.session?.id,
          isHalfBoard,
        })
          .then(() => {
            const currentParticipationUpdated = {
              ...user.currentParticipation,
              departure: endDate,
              arrival: startDate,
              isHalfBoard,
            };
            dispatch(
              saveUserState({
                ...user,
                currentParticipation: currentParticipationUpdated,
                phone: phone ?? user.phone,
              })
            );
          })
          .then(() => {
            setIsDisabled(false);
            navigate(`/${HOME}`);
          })
          .catch(() => {
            setIsDisabled(false);
            // eslint-disable-next-line no-console
            console.error(t('profileInformation.updateError'));
          });
      });
    }
    setIsDisabled(false);
  };

  // NOSONAR
  const onChangeFormInputs = (e) => {
    const checkNumber = /^[0-9\b]+$/;
    const { pattern, name, value } = e.target;
    if (pattern === '[0-9]*' && checkNumber.test(value) && value.length <= 10) {
      setErrors(false);
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    if (pattern === '[0-9]*' && !checkNumber.test(value)) {
      setValues((prev) => ({
        ...prev,
        [name]: values[name] || '',
      }));
      if (value === '') {
        setValues((prev) => ({
          ...prev,
          [name]: '',
        }));
      }
    }
    if (pattern !== '[0-9]*') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const inputsTexfields = (
    label,
    required,
    placeholder,
    helperText,
    type,
    pattern,
    name,
    readOnly
  ) => {
    if (name === 'startDate') {
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            disableToolbar
            label={label}
            readOnly={
              moment(user.currentParticipation?.session?.startDate).diff(
                moment(moment().toISOString()),
                'days'
              ) < daysLimitToFixPage
                ? true
                : readOnly
            }
            value={values[name]}
            minDate={moment()}
            maxDate={MAX_DATE}
            onChange={(e) => {
              let date;
              if (formatDateWithoutHours(e) === formatDateWithoutHours()) {
                date = values.startDate;
              } else {
                date = e;
              }
              if (moment(values?.endDate) <= moment(date)) {
                setValues((prev) => ({
                  ...prev,
                  [name]: formatDate(date),
                  endDate: formatDate(date),
                }));
              } else {
                setValues((prev) => ({
                  ...prev,
                  [name]: formatDate(date),
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                focused
                required={required || false}
                fullWidth
                name={name}
                key={name}
                type="date"
                error={
                  formatDateWithoutHours(values?.endDate) <
                  formatDateWithoutHours(values?.startDate)
                }
                helperText={
                  // 12 corresponds to Invalid Date, valid date is 24 or 25
                  values?.startDate?.length > 12 &&
                  values?.endDate?.length > 12 &&
                  (formatDateWithoutHours(values?.startDate) >
                  formatDateWithoutHours(MAX_DATE)
                    ? t('profileInformation.dateTooBigError')
                    : formatDateWithoutHours(values?.startDate) <
                      formatDateWithoutHours()
                    ? t('profileInformation.dateInPastError')
                    : '')
                }
                sx={{ height: '40px' }}
                onChange={(e) => {
                  if (moment(values?.endDate) <= moment(e)) {
                    setValues((prev) => ({
                      ...prev,
                      [name]: formatDate(e),
                      endDate: formatDate,
                    }));
                  } else
                    setValues((prev) => ({
                      ...prev,
                      [name]: formatDate(e),
                    }));
                }}
                {...params}
              />
            )}
            InputAdornmentProps={{
              sx: {
                backgroundColor: '#0188CE',
                height: '100%',
                maxHeight: 'none',
                margin: 0,
                borderRadius: '0 4px 0 0',
              },
            }}
            components={{
              OpenPickerIcon: () => <Today color="primary" />,
            }}
          />
        </LocalizationProvider>
      );
    }
    if (name === 'endDate') {
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            disableToolbar
            label={label}
            readOnly={
              moment(user.currentParticipation?.session?.startDate).diff(
                moment(moment().toISOString()),
                'days'
              ) < daysLimitToFixPage
                ? true
                : readOnly
            }
            value={values[name]}
            minDate={moment(values?.startDate)}
            maxDate={MAX_DATE}
            onChange={(e) => {
              let date;
              if (formatDateWithoutHours(e) === formatDateWithoutHours()) {
                date = values.endDate;
              } else {
                date = e;
              }
              setValues((prev) => ({
                ...prev,
                [name]: formatDate(date),
              }));
            }}
            renderInput={(params) => (
              <TextField
                focused
                required={required || false}
                fullWidth
                name={name}
                key={name}
                type="date"
                error={
                  formatDateWithoutHours(values?.endDate) <
                  formatDateWithoutHours(values?.startDate)
                }
                helperText={
                  // 12 corresponds to Invalid Date, valid date is 24 or 25
                  values?.startDate?.length > 12 &&
                  values?.endDate?.length > 12 &&
                  (values?.endDate < values?.startDate
                    ? helperText
                    : values?.endDate > formatDateWithoutHours(MAX_DATE)
                    ? t('profileInformation.dateTooBigError')
                    : values?.endDate < formatDateWithoutHours()
                    ? t('profileInformation.dateInPastError')
                    : '')
                }
                sx={{ height: '40px' }}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    [name]: formatDate(e),
                  }));
                }}
                {...params}
              />
            )}
            InputAdornmentProps={{
              sx: {
                backgroundColor: '#0188CE',
                height: '100%',
                maxHeight: 'none',
                margin: 0,
                borderRadius: '0 4px 0 0',
              },
            }}
            components={{
              OpenPickerIcon: () => <Today color="primary" />,
            }}
          />
        </LocalizationProvider>
      );
    }
    if (type === 'switch') {
      return (
        <>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            fullWidth
          >
            <FormControlLabel
              control={
                <Switch
                  className={classes.switchButton}
                  sx={{
                    m: 1,
                    width: 49,
                    height: 25,
                    padding: 0,
                    marginTop: '0.7em',
                  }}
                  checked={checked}
                  onChange={handleChangeAccomodation}
                />
              }
              label={label}
              labelPlacement="top"
              sx={{
                width: '100%',
                height: '2.5em',
                marginLeft: 0,
                alignItems: 'flex-start',
                fontSize: '1.2em',
              }}
              disabled={
                moment(user.currentParticipation?.session?.startDate).diff(
                  moment(moment().toISOString()),
                  'days'
                ) < daysLimitToFixPage
              }
            />
            <Typography
              variant="caption"
              sx={{
                fontSize: '12px',
                letterSpacing: '0px',
                lineHeight: '14px',
                textAlign: 'left',
                width: { xs: '60%', md: '18%' },
                marginLeft: '6em',
                position: 'absolute',
              }}
            >
              {t('profileInformation.switchDescription')}
            </Typography>
          </Grid>
        </>
      );
    }
    return (
      <TextField
        label={label}
        value={name === 'phone' ? values[name] ?? user.phone : values[name]}
        onChange={(e) => onChangeFormInputs(e)}
        focused
        required={required || false}
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        error={errors && name === 'phone'}
        helperText={errors ? helperText : ''}
        type={type}
        inputProps={{ pattern, readOnly }}
        name={name}
        key={name}
        sx={{ height: '40px' }}
      />
    );
  };

  return (
    <form noValidate onSubmit={(e) => onSubmitForm(e)}>
      <Grid container direction="row" rowSpacing={10} columnSpacing={5}>
        {/* Desktop */}
        {formInputs.map(
          ({
            label,
            required,
            placeholder,
            helperText,
            type,
            pattern,
            name,
            readOnly,
          }) => (
            <Grid item xs={12} md={4} className={classes.inputsDesktop}>
              {inputsTexfields(
                label,
                required,
                placeholder,
                helperText,
                type,
                pattern,
                name,
                readOnly
              )}
            </Grid>
          )
        )}
        {/* Mobile */}
        {formInputs
          .sort((a, b) => a.id - b.id)
          .map(
            ({
              label,
              required,
              placeholder,
              helperText,
              type,
              pattern,
              name,
              readOnly,
            }) => (
              <Grid item xs={12} md={4} className={classes.inputsMobile}>
                {inputsTexfields(
                  label,
                  required,
                  placeholder,
                  helperText,
                  type,
                  pattern,
                  name,
                  readOnly
                )}
              </Grid>
            )
          )}
        <Grid
          xs={12}
          md={4}
          className={classes.buttons}
          container
          gap={2}
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              md: 'row',
              padding: '20px 0px 0px 40px',
            },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={isDisabled}
          >
            {t('buttons.validate')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
