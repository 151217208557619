import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import moment from 'moment';
import { getHeight } from 'utils/dom.util';
import useStyles from './CourseCard.style';

export default function CourseCard({ event }) {
  const { name, room, startDate, endDate, trainers } = event;

  const classes = useStyles();

  const [showTrainers, setShowTrainers] = useState(true);

  const courseName = useRef(null);
  const courseDateTime = useRef(null);
  const courseTrainers = useRef(null);
  const courseContainer = useRef(null);
  const cardRefs = [courseName, courseDateTime, courseTrainers];

  const computeContentHeight = () => {
    return cardRefs.reduce((sum, cardElement) => {
      return sum + getHeight(cardElement.current, 40);
    }, 0);
  };

  const shouldShowTrainers = () => {
    const cardHeight = getHeight(courseContainer.current, 40);
    const contentHeight = computeContentHeight();

    setShowTrainers(cardHeight > contentHeight);
  };

  useEffect(() => {
    computeContentHeight();
    shouldShowTrainers();
    window.addEventListener('resize', shouldShowTrainers);
  }, []);

  const trainersWithBackSpace = () => {
    return trainers.map((trainer) => (
      <div>{`${trainer.firstname} ${trainer.lastname}`}</div>
    ));
  };

  return (
    <Grid
      className={classes.main}
      container
      direction="column"
      ref={courseContainer}
      data-testid="courseContainer"
    >
      <Grid ref={courseName}>
        <Typography
          variant="h6"
          sx={{
            lineHeight: '1rem',
            marginTop: '5px',
            fontSize: {
              xs: '0.85rem',
              md: '1rem',
            },
          }}
        >
          {name}
        </Typography>
      </Grid>

      <Grid ref={courseDateTime}>
        <Typography variant="p" className={classes.date}>
          {room.name} {moment(startDate).format('HH:mm')}-
          {moment(endDate).format('HH:mm')}
        </Typography>
      </Grid>

      {showTrainers ? (
        <Grid
          className={classes.gridTrainer}
          container
          direction="row-reverse"
          ref={courseTrainers}
        >
          <Typography variant="p" className={classes.trainer}>
            {trainersWithBackSpace()}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

CourseCard.propTypes = {
  event: PropTypes.shape().isRequired,
};
