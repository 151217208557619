import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import {
  KeyboardArrowUp,
  Menu as MenuIcon,
  FeedbackOutlined,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import ConnexionButton from 'components/ConnexionButton/ConnexionButton';
import { useMsal } from '@azure/msal-react';
import ProfilePicture from 'components/ProfilePicture/ProfilePicture';
import Popup from 'components/Popup/Popup';
import { FMEUR } from 'config/constants/Roles';
import { LogoSNCF } from '../../assets';
import useStyles, { menuElementStyles } from './HeaderBar.style';
import {
  ADMIN,
  COMMUNICATE_ITS_ARRIVAL,
  HOME,
  INTERVENTION_REQUEST,
  MY_PROFILE,
} from '../RoutesConstants';

function HeaderBar({ open, setOpen }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { instance: msalInstance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const communicate = location.pathname === `/${COMMUNICATE_ITS_ARRIVAL}`;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userMenuLinks, setUserMenuLinks] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const userMenuLinkstrainee = [
    {
      label: t('header.menu_profil'),
      onClick() {
        navigate(`/${MY_PROFILE}`);
      },
    },
    {
      label: t('header.menu_deconnexion'),
      onClick() {
        setPopupOpen(true);
      },
    },
  ];
  const userMenuLinksfmeur = [
    {
      label: t('header.menu_espace_administrateur'),
      onClick() {
        navigate(ADMIN);
      },
    },
    {
      label: t('header.menu_deconnexion'),
      onClick() {
        setPopupOpen(true);
      },
    },
  ];
  const user = useSelector((state) => state.user);
  const isUserFmeur = user?.role?.name === FMEUR;
  const userFullName = `${user?.firstname} ${user?.lastname}`;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setUserMenuLinks(isUserFmeur ? userMenuLinksfmeur : userMenuLinkstrainee);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const mobileMenuSelect = () => {
    setOpen(!open);
  };

  const classNameBurgerMenu = () => {
    if (location.pathname === `/${COMMUNICATE_ITS_ARRIVAL}`) {
      return classes.burgerMenuInvisible;
    }
    if (open) {
      return classes.burgerMenuOn;
    }
    if (!open) {
      return classes.burgerMenu;
    }

    return null;
  };

  return (
    <>
      <AppBar position="relative" className={classes.appBar} elevation={0}>
        <Toolbar disableGutters>
          {user?.isAuthenticated() ? (
            <MenuIcon
              className={classNameBurgerMenu()}
              onClick={mobileMenuSelect}
            />
          ) : null}

          <button
            type="button"
            onClick={() => {
              navigate(`/${HOME}`);
            }}
            className={classes.buttonLogo}
          >
            <img
              src={LogoSNCF}
              alt={t('header.logo_alt')}
              className={classes.imageLogo}
            />
          </button>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex' },
              flexGrow: 1,
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <b>{t('header.campus')}</b>&nbsp;{t('header.formation')}&nbsp;
            {user?.isAuthenticated() ? user?.campus?.name : null}
          </Typography>

          {user?.isAuthenticated() ? (
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', sm: 'none', md: 'block' },
              }}
            >
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                disabled={communicate}
              >
                <ProfilePicture userFullName={userFullName} />
              </IconButton>

              <Menu
                sx={{
                  mt: '45px',
                  display: { xs: 'none', sm: 'none', md: 'block' },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userMenuLinks.map((link) => (
                  <MenuItem
                    key={link.label}
                    onClick={() => {
                      link.onClick();
                      setAnchorElUser(null);
                    }}
                  >
                    <Typography textAlign="center">{link.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>

              <Button
                sx={{
                  marginLeft: '1.5em',
                  paddingX: '1em',
                  textTransform: 'uppercase',
                }}
                color="primary"
                variant="contained"
                disabled={communicate}
                onClick={() => navigate(`/${INTERVENTION_REQUEST}`)}
              >
                <FeedbackOutlined />
                &nbsp;{t('header.bouton_contribuer')}
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', sm: 'none', md: 'block' },
              }}
            >
              <ConnexionButton />
            </Box>
          )}

          {/* Mobile part */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              justifyContent: 'center',
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <b>{t('header.campus')}</b>&nbsp;{t('header.formation')}
          </Typography>

          {user?.isAuthenticated() ? (
            <Box
              sx={{
                flexGrow: 0,
                display: { sm: 'flex', md: 'none' },
                marginRight: '1em',
              }}
            >
              <IconButton
                data-testid="profile-menu"
                onClick={handleOpenUserMenu}
                disabled={communicate}
                sx={{ p: 0 }}
              >
                <ProfilePicture userFullName={userFullName} />
              </IconButton>

              <Menu
                sx={menuElementStyles.menu}
                PaperProps={menuElementStyles.paperProps}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                }}
                keepMounted
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userMenuLinks.map((link) => (
                  <MenuItem
                    key={link.label}
                    onClick={() => {
                      link.onClick();
                      setAnchorElUser(null);
                    }}
                    style={menuElementStyles.menuItem}
                  >
                    {link.label}
                  </MenuItem>
                ))}
                <KeyboardArrowUp onClick={handleCloseUserMenu} />
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{
                flexGrow: 0,
                display: { sm: 'flex', md: 'none' },
                marginRight: '0.1em',
              }}
            >
              <ConnexionButton mobile />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {popupOpen && (
        <Popup
          title={t('header.sign_out')}
          description={t('header.sign_out_description')}
          buttonText={t('common.validate')}
          handleClose={() => setPopupOpen(false)}
          handleAction={() => msalInstance.logout()}
        />
      )}
    </>
  );
}

export default HeaderBar;

HeaderBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
