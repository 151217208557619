import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import useStyles from './CustomDayHeader.style';

export default function CustomDayHeader({ date }) {
  const classes = useStyles();

  const weekStart = moment(date).startOf('week');

  const days = [];
  const workWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'];

  workWeek.forEach((el, i) => days.push(moment(weekStart).add(i, 'days')));

  return (
    <div className={classes.header}>
      {days.map((element) => {
        const isToday = moment(element).format() === moment(date).format();

        return (
          <div
            className={isToday ? classes.today : classes.restOfWeek}
            key={element.format('ddd')}
          >
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
              {element.format('ddd').toUpperCase()}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {element.format('DD')}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}

CustomDayHeader.propTypes = {
  date: PropTypes.string.isRequired,
};
