import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const globalTheme = createTheme(theme, {
  typography: {
    button: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    body1: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    body2: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    h1: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    h2: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    h3: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    h4: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    h5: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    h6: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    subtitle1: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
    subtitle2: {
      fontFamily: ['Avenir', 'Arial'].join(','),
    },
  },
  palette: {
    background: {
      default: '#F8F8F8',
    },
    primary: {
      main: '#FFF',
    },
    secondary: {
      main: '#0088CE',
      dark: '#0074af',
      contrastText: '#fff',
    },
    download: {
      main: '#3C3C3B',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          // paddingTop: '0.3em',
        },
      },
    },
    MuiThemeProvider: {
      styleOverrides: {
        root: {
          fontFamily: ['Avenir', 'Arial'].join(','),
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          fontFamily: ['Avenir', 'Arial'].join(','),
          borderRadius: '0.4375rem',
          backgroundColor: '#fff',
          backgroundClip: 'border-box',
          border: '1 solid rgba(0, 0, 0, 0.125)',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('md')]: {
            padding: '10px',
          },
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        display: 'inline-block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        border: '1px solid transparent',
        padding: '0.59375rem 1.875rem',
        borderRadius: '0.4375rem',
        text: {
          color: 'white',
        },
        containedPrimary: {
          backgroundColor: '#0188CE',
          borderRadius: '0.4375rem',
          '&:hover': {
            backgroundColor: '#0074af',
          },
          padding: '0.59375rem 1.875rem',
          textTransform: 'uppercase',
        },
        containedSecondary: {
          backgroundColor: '#4D4F53',
          borderRadius: '0.4375rem',
          '&:hover': {
            backgroundColor: '#333333',
          },
          textTransform: 'uppercase',
          padding: '0.59375rem 1.875rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          fill: 'inherit',
          color: 'inherit',
          minWidth: '42px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'text-top',
        },
      },
      root: {
        fill: 'white',
        backgroundColor: '#0088ce',
        borderRadius: '2px',
        fontSize: '2rem',
      },
      colorError: {
        fill: 'red',
        backgroundColor: 'inherit',
        // borderRadius: '100px',
        fontSize: '1em',
        margin: '0.2em',
      },
      colorAction: {
        backgroundColor: '#f9b000',
      },
      colorSecondary: {
        borderRadius: '30px',
        fill: '#FFB610',
        backgroundColor: 'white',
      },
      colorPrimary: {
        fill: '#0074af',
        backgroundColor: 'inherit',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 700,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fillOpacity: 0.5,
          '&.Mui-selected': {
            fillOpacity: 1,
            fill: '#0088CE',
            color: '#0088CE',
            borderRight: '3px solid #0088CE',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          borderBottom: '3px solid #0188CE',
          borderRadius: '4px 4px 0 0',
          color: '#000000',
          '& fieldset': {
            borderStyle: 'none',
          },
          '&.Mui-error': {
            borderBottom: '3px solid #CD0337',
          },
          [theme.breakpoints.down('md')]: {
            boxShadow: '0px 3px 6px #00000029',
          },
          '&.MuiInputBase-root': {
            '&.MuiInputBase-formControl': {
              '&.MuiInputBase-adornedEnd': {
                padding: 0,
              },
            },
          },
        },
        sizeSmall: {
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          border: '1px solid #0188CE',
          borderRadius: '5px',
          color: '#0188CE',
          '& fieldset': {
            borderStyle: 'none',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#000000',
            top: '-0.7em',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.2em',
          left: '-0.7em',
          '&.Mui-error': {
            color: '#CD0337',
          },
        },
        sizeSmall: {
          color: '#000000',
          top: '-0.7em',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        dayWithMargin: {
          '&.Mui-selected': {
            backgroundColor: '#0189D4',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            '&.MuiIconButton-edgeEnd': {
              '&.MuiIconButton-sizeMedium': {
                margin: 0,
              },
              '&.MuiIconButton-sizeSmall': {
                backgroundColor: '#0189D4',
                color: '#F5FBFE',
                position: 'absolute',
                left: '10px',
                boxShadow: '0px 3px 6px #00000029',
              },
            },
            '&.MuiIconButton-edgeStart': {
              '&.MuiIconButton-sizeSmall': {
                backgroundColor: '#0189D4',
                color: '#F5FBFE',
                boxShadow: '0px 3px 6px #00000029',
              },
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 6px #00000029',
          borderRadius: '10px',
          marginBottom: '1em',
          '&:first-of-type': {
            borderRadius: '10px',
          },
          '&:last-of-type': {
            borderRadius: '10px',
          },
          '&:before': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#CD0337',
          },
        },
      },
    },
  },
});
export default globalTheme;
