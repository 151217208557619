import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Pagination,
  ButtonGroup,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  GridOverlay,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Hosting from 'services/hosting.service';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './ResidentsList.style';
import ExportToExcelButton from './ExportToExcelButton/ExportToExcelButton';

export default function ResidentsList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState('');
  const [residentData, setResidentData] = useState([]);
  const [resultNumber, setResultNumber] = useState(residentData.length);
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [loading, setLoading] = useState(true);
  const { campus } = useSelector((state) => state.user);

  function selectStatus(
    isHalfBoard,
    arrival,
    departure,
    hotelName,
    hotelRoomNumber
  ) {
    if (hotelName || hotelRoomNumber) return t('residents_list.array.affected');
    if (isHalfBoard) return t('residents_list.array.halfBoard');
    if (!(!arrival && !departure))
      return t('residents_list.array.pre_registered');
    return t('residents_list.array.waiting');
  }

  useEffect(() => {
    Hosting.allByCampus(campus.id)
      .then((results) => {
        if (results.length) {
          setResultNumber(results.length);
        } else {
          setResultNumber(0);
        }
        setLoading(false);
        setResidentData(
          results.map((result) => {
            const {
              user,
              isHalfBoard,
              arrival,
              departure,
              hotelName,
              hotelRoomNumber,
            } = result;
            const status = selectStatus(
              isHalfBoard,
              arrival,
              departure,
              hotelName,
              hotelRoomNumber
            );
            const phoneNumber =
              user?.userAttributes?.find(
                (userAttribute) => userAttribute.name === 'phone'
              )?.value ?? '';
            return {
              ...result,
              ...user,
              id: user?.octopusUserId,
              status,
              phoneNumber,
              arrival: arrival ? moment(arrival).format('DD/MM/YYYY') : '',
              departure: departure
                ? moment(departure).format('DD/MM/YYYY')
                : '',
              hotelName,
              hotelRoomNumber,
            };
          })
        );
      })
      // eslint-disable-next-line no-console
      .catch(() => console.error('Une erreur est survenue\n'));
  }, []);

  let numberRows;
  const tabsSx = {
    fontSize: '13px',
    color: 'black',
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: '0',
    borderBottom: 'solid rgba(0,0,0,0.5) 2px',
    width: '13em',
    transform: 'translateY(2%)',
    '&:hover': { backgroundColor: 'transparent' },
  };

  const columns = [
    {
      field: 'cp',
      headerName: t('residents_list.array.cp'),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: t('residents_list.array.lastname'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: '13px',
              textTransform: 'uppercase',
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'firstname',
      headerName: t('residents_list.array.firstname'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('residents_list.array.status'),
      minWidth: 125,
      flex: 1,
      renderCell: (params) => {
        let bgColor;
        let fColor;
        switch (params.value) {
          case `${t('residents_list.array.halfBoard')}`:
            bgColor = '#000';
            fColor = '#FFF';
            break;
          case `${t('residents_list.array.pre_registered')}`:
            bgColor = '#0088CE';
            fColor = '#FFF';
            break;
          case `${t('residents_list.array.waiting')}`:
            bgColor = '#F2F2F2';
            fColor = '#3C3C3B';
            break;
          case `${t('residents_list.array.affected')}`:
            bgColor = '#AA056D';
            fColor = '#FFF';
            break;
          default:
            bgColor = '#F3F3F3';
            fColor = '#3C3C3B';
            break;
        }
        return (
          <Typography
            sx={{
              backgroundColor: bgColor,
              color: fColor,
              fontSize: '13px',
              padding: '0.3em 1em',
              borderRadius: '50px',
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'arrival',
      headerName: t('residents_list.array.arrival_date'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'departure',
      headerName: t('residents_list.array.departure_date'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'email',
      headerName: t('residents_list.array.mail'),
      minWidth: 190,
      flex: 2,
    },
    {
      field: 'phoneNumber',
      headerName: t('residents_list.array.phone_number'),
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'hotelRoomNumber',
      headerName: t('residents_list.array.room_number'),
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'hotelName',
      headerName: t('residents_list.array.hotel_name'),
      minWidth: 600,
      flex: 5,
    },
  ];

  // Header bar
  function CustomToolbar() {
    return (
      <>
        <GridToolbarContainer
          sx={{
            borderBottom: 'solid rgba(0,0,0,0.5) 1px',
            padding: '0.4em 0 0 0',
          }}
        >
          <ButtonGroup>
            <Button
              variant="contained"
              sx={
                selectedBtn === 0
                  ? {
                      ...tabsSx,
                      borderRadius: '0.4375rem 0 0 0',
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                      borderRadius: '0.4375rem 0 0 0',
                    }
              }
              className={classes.tabButton}
              data-testid="noFilterButton"
              onClick={() => {
                setFilterValue('');
                setResultNumber(residentData.length);
                setSelectedBtn(0);
              }}
            >
              {t('residents_list.array.all')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 2
                  ? {
                      ...tabsSx,
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                    }
              }
              className={classes.tabButton}
              data-testid="waitingFilterButton"
              onClick={() => {
                setFilterValue(t('residents_list.array.waiting'));
                numberRows = residentData.filter(
                  (resident) =>
                    resident.status === t('residents_list.array.waiting')
                ).length;
                setResultNumber(numberRows);
                setSelectedBtn(2);
              }}
            >
              {t('residents_list.array.waiting')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 1
                  ? {
                      ...tabsSx,
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                    }
              }
              className={classes.tabButton}
              data-testid="pre_registeredFilterButton"
              onClick={() => {
                setFilterValue(t('residents_list.array.pre_registered'));
                numberRows = residentData.filter(
                  (resident) =>
                    resident.status === t('residents_list.array.pre_registered')
                ).length;
                setResultNumber(numberRows);
                setSelectedBtn(1);
              }}
            >
              {t('residents_list.array.pre_registered')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 4
                  ? {
                      ...tabsSx,
                      borderRadius: '0 0.4375rem 0 0',
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                      borderRadius: '0 0.4375rem  0 0',
                    }
              }
              className={classes.tabButton}
              data-testid="affectedFilterButton"
              onClick={() => {
                setFilterValue(t('residents_list.array.affected'));
                numberRows = residentData.filter(
                  (resident) =>
                    resident.status === t('residents_list.array.affected')
                ).length;
                setResultNumber(numberRows);
                setSelectedBtn(4);
              }}
            >
              {t('residents_list.array.affected')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 3
                  ? {
                      ...tabsSx,
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                    }
              }
              className={classes.tabButton}
              data-testid="autonomousFilterButton"
              onClick={() => {
                setFilterValue(t('residents_list.array.halfBoard'));
                numberRows = residentData.filter(
                  (resident) =>
                    resident.status === t('residents_list.array.halfBoard')
                ).length;
                setResultNumber(numberRows);
                setSelectedBtn(3);
              }}
            >
              {t('residents_list.array.halfBoard')}
            </Button>
          </ButtonGroup>
        </GridToolbarContainer>
        <GridToolbarContainer>
          <Typography
            sx={{
              fontSize: '13px',
              opacity: '50%',
            }}
          >
            {t('residents_list.array.results', { count: resultNumber })}
          </Typography>
        </GridToolbarContainer>
      </>
    );
  }

  // Pagination
  function CustomPagination() {
    const apiContext = useGridApiContext();
    const page = useGridSelector(apiContext, gridPageSelector);
    const pageCount = useGridSelector(apiContext, gridPageCountSelector);
    return (
      <Pagination
        color="secondary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiContext.current.setPage(value - 1)}
      />
    );
  }

  // No rows
  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column',
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>{t('residents_list.array.no_results')}</Box>
      </StyledGridOverlay>
    );
  }

  // No filtered rows
  function CustomNoResultsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>{t('residents_list.array.no_results_filter')}</Box>
      </StyledGridOverlay>
    );
  }

  function LoadingRowOverlay() {
    const skeleton = [];
    for (let i = 0; i < 12; i += 1) {
      skeleton.push(
        <div style={{ position: 'absolute', top: 40 * i, width: '100%' }}>
          <Skeleton variant="text" animation="wave" height={40} />
        </div>
      );
    }
    return <GridOverlay>{skeleton}</GridOverlay>;
  }

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700, marginBottom: '0.3em' }}
                >
                  {t('residents_list.title')}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                sx={{
                  width: '95%',
                  marginBottom: '1em',
                }}
              >
                <Grid item xs={12} md={9}>
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="body1"
                      className={classes.description}
                      gutterBottom
                    >
                      {t('residents_list.description')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    alignSelf: 'flex-end',
                    marginBottom: '1em',
                  }}
                >
                  <ExportToExcelButton ResidentData={residentData} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ height: window.innerHeight - 100 }}
              >
                <DataGrid
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    NoResultsOverlay: CustomNoResultsOverlay,
                    Pagination: CustomPagination,
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LoadingRowOverlay,
                  }}
                  rows={residentData}
                  columns={columns}
                  disableColumnMenu
                  disableSelectionOnClick
                  disableVirtualization
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnFilter
                  hideFooterSelectedRowCount
                  loading={loading}
                  sx={{
                    width: '95%',
                    fontSize: '13px',
                    backgroundColor: '#FFF',
                    paddingX: '1em',
                  }}
                  pagination
                  pageSize={50}
                  rowsPerPageOptions={[5]}
                  filterModel={{
                    items: [
                      {
                        columnField: 'status',
                        operatorValue: 'contains',
                        value: filterValue,
                      },
                    ],
                  }}
                  columnBuffer={10}
                  isRowSelectable={() => false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
