import React, { useEffect } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { me, saveUserState } from 'redux/reducers/userReducer';
import RequestAccessToken from 'services/requestAccessToken.service';
import RouterConfig from './navigation/RouterConfig';
import globalTheme from './styles/globalTheme';

const theme = createTheme(globalTheme);

function App() {
  const isUserAuthentificated = useIsAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserAuthentificated) {
      dispatch(me());

      let options;
      RequestAccessToken((response) => {
        const { accessToken } = response;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'image/jpeg');
        options = {
          method: 'GET',
          headers,
        };
      }).then(() =>
        fetch('https://graph.microsoft.com/v1.0/me/photo/$value', options)
          .then((response) => {
            if (response && response.ok) {
              response.blob().then((data) => {
                if (data) {
                  window.URL = window.URL || window.webkitURL;
                  dispatch(
                    saveUserState({
                      photoUrl: window.URL.createObjectURL(data),
                    })
                  );
                }
              });
            }
          })
          .catch((error) => {
            console.error('Profile photo not found', error);
          })
      );
    }
  }, [isUserAuthentificated]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
