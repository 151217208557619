import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import useStyles from './InputText.style';

function InputText(props) {
  const classes = useStyles();

  const { className, multiline, ...textFieldProps } = props;

  return (
    <TextField
      {...textFieldProps}
      hiddenLabel
      multiline={multiline}
      className={[className, classes.inputText].join(' ')}
      InputProps={{
        style: { border: multiline && 'none' },
        shrink: true,
      }}
      sx={{
        margin: { xs: '1em 0', md: '2em 0' },
      }}
    />
  );
}

InputText.propTypes = {
  multiline: PropTypes.bool,
  className: PropTypes.string,
};

InputText.defaultProps = {
  className: '',
  multiline: false,
};

export default InputText;
