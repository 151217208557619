import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import useStyles from './CustomDateHeader.style';

export default function CustomDateHeader({ label }) {
  const classes = useStyles();

  const number = label.split(' ')[0];
  const text = label.split(' ')[1].toUpperCase();

  return (
    <div className={classes.header}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
        {text}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {number}
      </Typography>
    </div>
  );
}

CustomDateHeader.propTypes = {
  label: PropTypes.string.isRequired,
};
