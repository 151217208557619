import React from 'react';
import { Grid, Card, CardContent, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TrashCanOutline } from '../../assets';

export default function CardAnomalySkeletonMobile() {
  const { t } = useTranslation();
  const labelStyle = { color: '#0188CE' };
  const skeleton = [];
  for (let i = 0; i < 5; i += 1) {
    skeleton.push(
      <Card
        sx={{
          backgroundColor: '#FAFAFA',
          marginBottom: '2em',
          paddingTop: '2em',
        }}
        id={`skeleton_${i}`}
      >
        <CardContent>
          <Grid container direction="row" rowSpacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={7} style={labelStyle}>
              {t('myrequest.array.status')}
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={7} style={labelStyle}>
              {t('myrequest.array.place')}
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={7} style={labelStyle}>
              {t('myrequest.array.createDate')}
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={7} style={labelStyle}>
              {t('myrequest.array.updateDate')}
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={12} style={labelStyle}>
              {t('myrequest.array.commentary')}
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TrashCanOutline style={{ color: 'grey' }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  return skeleton;
}
