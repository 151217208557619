import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import UploadCard from 'components/UploadCard/UploadCard';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './Hebergement.style';
import Participations from '../../services/participations.service';

export default function Hebergement() {
  const { t } = useTranslation();
  const classes = useStyles();
  const uploadFile = (file) => Participations.uploadAccommodationFile(file);

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700, marginBottom: '0.3em' }}
                >
                  {t('hebergement.title')}
                </Typography>
              </Grid>
              <Grid item xs={11} md={11}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                >
                  {t('hebergement.description')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12}>
                <UploadCard
                  importLabel={t('hebergement.import_label')}
                  fileSendLabel={t('hebergement.file_send')}
                  typeFile={['XLSX']}
                  sizeMaxFile="1 Mo"
                  uploadFile={uploadFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
