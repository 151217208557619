import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
      zIndex: theme.zIndex.drawer + 1,
    },
    visibility: 'collapse',
  },
  drawerInvisible: {
    display: 'none',
    visibility: 'hidden',
  },
  drawerOn: {
    visibility: 'visible',
  },
  drawerHeader: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  imageLogo: {
    width: '100px',
  },
  buttonLogoOn: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
    background: 'none',
    border: 'none',
    width: '120px',
    marginLeft: '0.9em',
  },
  buttonLogo: {
    display: 'none',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export default useStyles;
