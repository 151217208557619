import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import LabelCard from '../../components/LabelCard/LabelCard';
import useStyles from './GlobalFormation.style';
import { Formation } from '../../assets';

export default function GlobalFormation() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={9}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700 }}
                >
                  {t('global_formation.title')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                >
                  {t('global_formation.description')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12} md={4}>
                <LabelCard
                  label={t('global_formation.catalogue')}
                  link="https://sncf.sharepoint.com/sites/CataloguedeFormationSNCFRESEAU"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LabelCard
                  label={t('global_formation.ways_forward')}
                  link="https://sncf.sharepoint.com/sites/voies-davenir?OR=Teams-HL&CT=1636041890148"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LabelCard
                  label={t('global_formation.elearning')}
                  link="https://monacademie.sncf.fr/"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} md={3}>
            <img
              src={Formation}
              alt={t('img.formation')}
              className={classes.img}
            />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
