import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './IconLabelCard.style';

function IconLabelCard({ label, link, Icon, sxCard }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = () => {
    navigate(link);
  };

  return (
    <Card className={classes.card} onClick={handleClick} sx={sxCard}>
      <CardContent className={classes.cardContent}>
        <Icon
          className={classes.icon}
          sx={{
            fontSize: 50,
          }}
        />
        <Typography variant="h6" className={classes.title} gutterBottom>
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}

IconLabelCard.defaultProps = {
  sxCard: null,
};

IconLabelCard.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  sxCard: PropTypes.shape(),
};

export default IconLabelCard;
