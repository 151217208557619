import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'pages/Loading/Loading';
import ResidentsList from 'pages/ResidentsList/ResidentsList';
import ArrivalAnnouncement from 'pages/ArrivalAnnouncement/ArrivalAnnouncement';
import InterventionRequest from 'pages/InterventionRequest/InterventionRequest';
import moment from 'moment';
import MyProfile from 'pages/MyProfile/MyProfile';
import Agenda from '../pages/Agenda/Agenda';
import NotFound from './NotFound/NotFound';
import Home from '../pages/Home/Home';
import Accomodation from '../pages/Accomodation/Accomodation';
import PublicHome from '../pages/PublicHome/PublicHome';
import CampusInformation from '../pages/CampusInformation/CampusInformation';
import GlobalFormation from '../pages/GlobalFormation/GlobalFormation';
import Hebergement from '../pages/Hebergement/Hebergement';
import News from '../pages/News/News';
import {
  APP_LOADING,
  AUTH_CALLBACK,
  ROOT,
  NOTFOUND,
  CAMPUS_INFORMATION,
  WELCOME_BOOKLET,
  GLOBAL_FORMATION,
  AGENDA,
  HOME,
  APP_TO_CONSTRUCT,
  RESIDENTS_LIST,
  HEBERGEMENT,
  COMMUNICATE_ITS_ARRIVAL,
  INTERVENTION_REQUEST,
  ADMIN,
  MY_PROFILE,
  NEWS,
  ACCOMMODATION,
  WEEK_MENU_UPLOAD,
} from './RoutesConstants';
import LeftMenu from './Drawer/LeftMenu';
import HeaderBar from './HeaderBar/HeaderBar';
import WelcomeBooklet from '../pages/WelcomeBooklet/WelcomeBooklet';
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';
import FormationRoute from './auth/FormationRoute';
import InConstruction from '../pages/InConstruction/InConstruction';
import AdminSpace from '../pages/AdminSpace/AdminSpace';
import WeekMenuUpload from '../pages/WeekMenuUpload/WeekMenuUpload';

function RouterConfig() {
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const today = moment();

  if (
    user?.isAuthenticated() &&
    // ne pas enlever le === null, il faut que la valeur retournée soit `null` et pas `undefined`
    user?.currentParticipation?.departure === null &&
    user?.currentParticipation?.arrival === null &&
    today.isBefore(user?.currentParticipation?.session?.startDate) &&
    today.isBefore(user?.currentParticipation?.session?.endDate) &&
    window.location.pathname !== `/${COMMUNICATE_ITS_ARRIVAL}`
  ) {
    navigate(`/${COMMUNICATE_ITS_ARRIVAL}`);
  }

  return (
    <>
      <HeaderBar open={openBurgerMenu} setOpen={setOpenBurgerMenu} />

      {user?.isAuthenticated() && (
        <LeftMenu open={openBurgerMenu} setOpen={setOpenBurgerMenu} />
      )}

      <Routes>
        {/* List all public routes here */}
        <Route exact path={ROOT} element={<PublicHome />} />
        <Route exact path={APP_TO_CONSTRUCT} element={<InConstruction />} />
        <Route exact path={AUTH_CALLBACK} element={<Loading />} />
        <Route exact path={APP_LOADING} element={<Loading />} />
        {/* List all private/auth routes here */}
        <Route
          exact
          path={HOME}
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={WELCOME_BOOKLET}
          element={
            <PrivateRoute>
              <WelcomeBooklet />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AGENDA}
          element={
            <PrivateRoute>
              <Agenda />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={CAMPUS_INFORMATION}
          element={
            <PrivateRoute>
              <CampusInformation />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={GLOBAL_FORMATION}
          element={
            <PrivateRoute>
              <GlobalFormation />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={INTERVENTION_REQUEST}
          element={
            <PrivateRoute>
              <InterventionRequest />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={ACCOMMODATION}
          element={
            <PrivateRoute>
              <Accomodation />
            </PrivateRoute>
          }
        />

        {/* List all trainee/trainer routes here */}

        <Route
          exact
          path={MY_PROFILE}
          element={
            <FormationRoute>
              <MyProfile />
            </FormationRoute>
          }
        />

        {/* List all admin routes here */}
        <Route
          exact
          path={ADMIN}
          element={
            <AdminRoute>
              <AdminSpace />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={RESIDENTS_LIST}
          element={
            <AdminRoute>
              <ResidentsList />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={HEBERGEMENT}
          element={
            <AdminRoute>
              <Hebergement />
            </AdminRoute>
          }
        />

        <Route
          exact
          path={NEWS}
          element={
            <AdminRoute>
              <News />
            </AdminRoute>
          }
        />

        <Route
          exact
          path={WEEK_MENU_UPLOAD}
          element={
            <AdminRoute>
              <WeekMenuUpload />
            </AdminRoute>
          }
        />

        {user?.isAuthenticated() &&
          user?.currentParticipation?.departure === null &&
          user?.currentParticipation?.arrival === null && (
            <Route
              exact
              path={COMMUNICATE_ITS_ARRIVAL}
              element={
                <PrivateRoute>
                  <ArrivalAnnouncement />
                </PrivateRoute>
              }
            />
          )}
        {/* A generic 404-Not Found route here */}
        <Route path={NOTFOUND} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default RouterConfig;
