import { useIsAuthenticated } from '@azure/msal-react';
import { TRAINEE } from 'config/constants/Roles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { saveUserState } from 'redux/reducers/userReducer';

function Loading() {
  // TODO CHANGE ME
  const USER_STUB = {
    firstname: 'DS',
    lastname: 'ITN',
    role: TRAINEE,
    email: 'dsitn@sncf.fr',
  };

  const dispatch = useDispatch();
  const isUserAuthentificated = useIsAuthenticated();

  if (isUserAuthentificated) {
    // Call graphql Endpoint campus formation
    // save user information after checking if valid
    dispatch(saveUserState(USER_STUB));
  }

  return <div>Chargement du profil</div>;
}

Loading.propTypes = {};

export default Loading;
