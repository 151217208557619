import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '1em 1.8em',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
