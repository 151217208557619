import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  main: {
    margin: '1.7em',
  },
  title: {
    textTransform: 'uppercase',
  },
  accordionTitles: {
    textTransform: 'uppercase',
  },
  icon: {
    color: 'black',
  },
}));

export default useStyles;
