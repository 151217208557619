import { makeStyles } from '@mui/styles';

const tabTitle = {
  borderRadius: '4px 4px 4px 4px !important',
  border: '0px',
  backgroundColor: 'white',
  marginRight: 10,
  padding: 10,
  marginBottom: 10,
};

const useStyles = makeStyles(() => ({
  tabTitle,
  tabTitleActive: {
    ...tabTitle,
    marginBottom: 0,
    borderRadius: '4px 4px 0px 0px !important',
    '&::after': {
      // eslint-disable-next-line prettier/prettier
      content: '" "',
      position: 'relative',
      background: 'white',
      float: 'left',
      width: '100%',
      height: '10px',
    },
  },
  card: {
    width: '96%',
    border: '0px',
    boxShadow: 'none !important',
    borderTopLeftRadius: '0px !important',
  },
}));

export default useStyles;
