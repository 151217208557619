import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  RestaurantOutlined,
  LocalHotel,
  NewspaperOutlined,
  FeedbackOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import IconLabelCard from '../../components/IconLabelCard/IconLabelCard';
import { AccountGroupOutline } from '../../assets';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './AdminSpace.style';
import { WEEK_MENU_UPLOAD } from '../../navigation/RoutesConstants';

function AdminSpace() {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const title = t('title_campus', { name: user?.campus?.name });
  const description = t('admin_space.description', {
    name: user?.campus?.name,
  });

  const svgIconFormat = () => {
    return (
      <AccountGroupOutline
        data-testid="organigrammeIcon"
        className={classes.svgIcon}
      />
    );
  };

  const iconList = [
    {
      icon: RestaurantOutlined,
      label: t('admin_space.week-menu'),
      url: `/${WEEK_MENU_UPLOAD}`,
    },
    {
      icon: LocalHotel,
      label: t('hebergement.title'),
      url: '/espace-administrateur/hebergement',
    },
    {
      icon: NewspaperOutlined,
      label: t('admin_space.news'),
      url: '/espace-administrateur/actualites',
    },
    {
      icon: FeedbackOutlined,
      label: t('admin_space.contribute'),
      url: '/en-construction',
    },
    {
      icon: svgIconFormat,
      label: t('residents_list.title'),
      url: '/espace-administrateur/liste-residents',
    },
  ];

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={10}>
            <Grid container className={classes.leftContainer}>
              <Typography
                variant="h5"
                className={classes.title}
                sx={{ fontWeight: 700 }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                >
                  {description}
                </Typography>
              </Grid>
              <Grid container>
                {iconList.map((item) => {
                  return (
                    <Grid key={item.label} item xs={6} md={3}>
                      <IconLabelCard
                        label={item.label}
                        link={item.url}
                        Icon={item.icon}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}

export default AdminSpace;
