import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'center',
    margin: '1em 1em 1em 0',
    maxWidth: '50em',
    [theme.breakpoints.up('md')]: {
      marginRight: '2em',
    },
    opacity: 0.5,
  },
  cardIsActive: {
    textAlign: 'center',
    margin: '1em 1em 1em 0',
    maxWidth: '50em',
    [theme.breakpoints.up('md')]: {
      marginRight: '2em',
    },
  },
  cardMedia: {
    height: '100px',
    [theme.breakpoints.up('md')]: {
      height: '150px',
    },
  },
}));

export default useStyles;
