import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1,
      left: 0,
      padding: '0.4em 1.5em 0.4em 1em',
    },
    zIndex: theme.zIndex.drawer - 1,
    padding: '0.4em',
  },
  buttonLogo: {
    margin: '0.5em',
    background: 'none',
    border: 'none',
    color: 'inherit',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imageLogo: {
    width: '100px',
    [theme.breakpoints.down('md')]: {
      visibility: 'collapse',
    },
  },
  burgerMenuInvisible: {
    display: 'none',
    visibility: 'hidden',
  },
  burgerMenuOn: {
    marginLeft: '1rem',
    marginBottom: '1rem',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'collapse',
      position: 'fixed',
    },
  },
  burgerMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'collapse',
      position: 'fixed',
    },
    zIndex: theme.zIndex.appBar + 1,
    marginLeft: '1rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export const menuElementStyles = {
  menuItem: {
    textAlign: 'center',
    display: 'block',
    fontWeight: 600,
  },
  paperProps: {
    style: {
      left: 0,
      width: '110%',
      maxWidth: '110%',
      boxShadow: 'none',
      display: 'block',
    },
    sx: {
      left: 0,
      top: '20px',
      marginLeft: '5%',
      paddingLeft: '10%',
      textAlign: 'center',
    },
  },
  menu: {
    position: 'fixed',
    mt: '45px',
    left: 0,
    display: { xs: 'block', sm: 'block', md: 'none' },
  },
};

export default useStyles;
