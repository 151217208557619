import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useStyles from './UseLocationMenu.style';

export default function UseLocationMenu() {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();

  const addressArray = location.pathname.split('/', -1);
  const name = addressArray[1];
  let translation = '';

  Object.values(addressArray.slice(2)).forEach((addressPart) => {
    const showAddress =
      addressPart !== '/' && addressPart !== ''
        ? t(`useLocationMenu.${addressPart}`)
        : '';
    translation += showAddress.length > 0 ? ` > ${showAddress}` : showAddress;
  });

  return (
    <Grid item xs={12} md={12} className={classes.container}>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.text}
        sx={{ fontWeight: '700', mt: '0.62em' }}
      >
        {t(`useLocationMenu.${name}`)}
        {translation !== '' && `${translation}`}
      </Typography>
    </Grid>
  );
}
