import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '10em',
    aspectRatio: 19 / 20,
    textAlign: 'center',
    margin: '1em auto',
    maxWidth: '95%',
    [theme.breakpoints.up('md')]: {
      aspectRatio: 3 / 2,
      maxWidth: '15em',
      margin: '1em 0',
    },
    cursor: 'pointer',
  },
  cardContent: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
  },
  icon: {
    margin: '0.3em',
    [theme.breakpoints.down('md')]: {
      margin: '0.1em',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.00rem !important',
    },
  },
}));

export default useStyles;
