/* eslint-disable import/prefer-default-export */
const { port, hostname, protocol } = window.location;

const PORT = port ? `:${port}` : '';

export const ROOT = '/';
export const ROOT_URL = `${protocol}//${hostname}${PORT}${ROOT}`;
export const NOTFOUND = '*';

export const AGENDA = 'agenda';
export const APP_LOADING = 'chargement';
export const APP_TO_CONSTRUCT = 'en-construction';
export const AUTH_CALLBACK = 'callback';
export const CAMPUS_INFORMATION = 'information-campus';
export const ACCOMMODATION = `${CAMPUS_INFORMATION}/un-bon-lit-pour-dormir`;
export const COMMUNICATE_ITS_ARRIVAL = 'communiquer-son-arrivee';
export const GLOBAL_FORMATION = 'formation';
export const HOME = 'accueil';
export const MY_PROFILE = 'mon-profil';
export const WELCOME_BOOKLET = `${CAMPUS_INFORMATION}/livret-accueil`;

export const ADMIN = 'espace-administrateur';
export const HEBERGEMENT = `${ADMIN}/hebergement`;
export const INTERVENTION_REQUEST = 'demande-intervention';
export const RESIDENTS_LIST = `${ADMIN}/liste-residents`;
export const NEWS = `${ADMIN}/actualites`;
export const WEEK_MENU_UPLOAD = `${ADMIN}/menu-semaine`;
