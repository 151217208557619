import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ValidationForm from 'utils/ValidationForm/ValidationForm';
import CheckIcon from '@mui/icons-material/Check';
import Ressource from 'services/ressource.service';
import Anomaly from 'services/anomaly.service';

import InterventionRequestDesktop from './InterventionRequestDesktop/InterventionRequestDesktop';
import InterventionRequestMobile from './InterventionRequestMobile/InterventionRequestMobile';

function InterventionRequest() {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  const [sendAnomalies, setSendAnomalies] = useState(0);
  const [anomalies, setAnomalies] = useState([]);
  const [loadingAnomalies, SetLoadingAnomalies] = useState(true);
  const [categories, setCategories] = useState([]);
  const [buildings, setBuildings] = useState([]);

  const [form, setForm] = useState({
    category: '',
    type: '',
    building: '',
    floor: '',
    site: '',
    comment: '',
  });

  const [errors, setErrors] = useState(false);
  const [messageSent, setMessageSent] = useState(null);

  const setFormValueOf = (key, value) => {
    if (Object.keys(form).includes(key)) {
      const newForm = { ...form };
      newForm[key] = value;
      setForm(newForm);
    }
  };

  useEffect(async () => {
    if (user?.campus) {
      try {
        const site = await Ressource.searchSiteForOctopusCampusId(
          user.campus.id
        );
        Anomaly.bySite({ userId: user.id, id: site.id })
          .then((Anomalies) => {
            setAnomalies(Anomalies);
            SetLoadingAnomalies(false);
          })
          .catch(() =>
            console.error(
              "Une erreur est survenue pendant le chargement de la liste d'anomalies"
            )
          );
        if (site) {
          const categoriesForSite =
            await Anomaly.getCategoriesForSiteRessourceId(site.id);

          setFormValueOf('site', site.id);
          setBuildings(site?.buildings || []);
          setCategories(categoriesForSite || []);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [user]);

  const formatValidateMessage = (
    <>
      <CheckIcon
        sx={{
          margin: '0 0.2em',
          verticalAlign: 'middle',
          fontSize: '1.8rem',
        }}
      />
      {t('interventionRequest.messageSent')}
    </>
  );

  const sendNext = () =>
    Anomaly.create({
      typeId: form.type,
      creatorUserId: user?.id,
      floorRessourceId: form.floor,
      comment: form.comment,
    })
      .then((anomaly) => {
        const anomaliesCopy = [...anomalies];
        anomaliesCopy.push(anomaly);
        setAnomalies(anomaliesCopy);
        setMessageSent(formatValidateMessage);
        setForm({
          category: '',
          type: '',
          building: '',
          floor: '',
          site: '',
          comment: '',
        });
        setErrors(false);
        setTimeout(() => {
          setSendAnomalies(1);
          setMessageSent('');
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
      });

  const sendForm = () => ValidationForm(setErrors, sendNext);

  const helperTextError = (inputValue) =>
    errors && !inputValue && t('interventionRequest.create.helperTextError');

  const deleteAnomaly = (id) => {
    Anomaly.delete(id)
      .then(() => {
        const anomaliesCopy = anomalies.filter((anomaly) => anomaly.id !== id);
        setAnomalies(anomaliesCopy);
      })
      .catch(() =>
        console.error(
          "Une erreur est survenue pendant la suppression de l'anomalie"
        )
      );
  };

  return (
    <main>
      {window.innerWidth > 900 ? (
        <InterventionRequestDesktop
          siteName={user?.campus?.name}
          categories={categories}
          buildings={buildings}
          form={form}
          setFormValueOf={setFormValueOf}
          sendForm={sendForm}
          errors={errors}
          helperTextError={helperTextError}
          messageSent={messageSent}
          anomalies={anomalies}
          loadingAnomalies={loadingAnomalies}
          deleteAnomaly={deleteAnomaly}
          sendAnomalies={sendAnomalies}
          setSendAnomalies={setSendAnomalies}
        />
      ) : (
        <InterventionRequestMobile
          siteName={user?.campus?.name}
          categories={categories}
          buildings={buildings}
          form={form}
          setFormValueOf={setFormValueOf}
          sendForm={sendForm}
          errors={errors}
          helperTextError={helperTextError}
          messageSent={messageSent}
          setForm={setForm}
          anomalies={anomalies}
          loadingAnomalies={loadingAnomalies}
          deleteAnomaly={deleteAnomaly}
          sendAnomalies={sendAnomalies}
          setSendAnomalies={setSendAnomalies}
        />
      )}
    </main>
  );
}

export default InterventionRequest;
