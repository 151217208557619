import { gql } from '@apollo/client';
import { GraphqlQuery, GraphqlMutate } from '../config/apolloClient';
import RequestAccessToken from './requestAccessToken.service';

const FormData = require('form-data');
const axios = require('axios');

const ALL_NEWS_BY_SITE = gql`
  query findEventsBySiteId($id: Int!) {
    findEventsBySiteId(id: $id) {
      id
      name
    }
  }
`;

const DELETE_NEWS = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;

export default class Event {
  static allBySite(id) {
    return GraphqlQuery(ALL_NEWS_BY_SITE, { id })
      .then((result) => result.findEventsBySiteId)
      .catch((e) => e);
  }

  static createEvent(file, siteId) {
    const data = new FormData();
    let config;
    data.append(
      'operations',
      `{ "query":" mutation createEvent($file: Upload!, $siteId: Int!) { createEvent(file: $file, siteId: $siteId){id name} }", "variables": { "file": null, "siteId": ${+siteId} } }`
    );
    data.append('map', '{ "0": ["variables.file"] }');
    data.append('0', file);
    return RequestAccessToken((response) => {
      const { accessToken } = response;
      config = {
        method: 'post',
        url: process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_GRAPHQL_ENPOINT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data,
      };
    }).then(() =>
      axios(config)
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        })
    );
  }

  static delete(id) {
    return GraphqlMutate(DELETE_NEWS, {
      id,
    })
      .then((result) => result.deleteEvent)
      .catch((e) => e);
  }
}
