import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import useStyles from './PublicHome.style';

import LocationCard from '../../components/LocationCard/LocationCard';
import { HOME } from '../../navigation/RoutesConstants';

function PublicHome() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const title = t('public_home.title');
  const description = t('public_home.description');

  useEffect(() => {
    if (user?.isAuthenticated()) {
      navigate(`/${HOME}`);
    }
  });

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <Grid item xs={12} md={12}>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  sx={{ fontWeight: '700', letterSpacing: '0px' }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{
                    marginBottom: 2,
                    fontSize: {
                      xs: 14,
                      sm: 16,
                      md: 18,
                    },
                  }}
                >
                  {description}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <LocationCard
                  label={t('public_home.campus_Bordeaux')}
                  isActive
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <LocationCard
                  label={t('public_home.campus_Lyon')}
                  isActive={false}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <LocationCard
                  label={t('public_home.campus_Paris')}
                  isActive={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}

export default PublicHome;
