import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  titles: {
    color: '#0188CE',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
