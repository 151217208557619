/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconCard from '../IconCard/IconCard';
import useStyles from './MenuItemComponent.style';

function MenuItemComponent({ label, link, Icon, description }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const handlerHover = () => {
    const lastHover = hover;
    setHover(!lastHover);
  };
  const handleClick = () => {
    navigate(link);
  };

  return (
    <Grid
      container
      className={classes.background}
      direction="row"
      columnGap={2}
      onClick={handleClick}
    >
      <Grid item xs={3} md={12} className={classes.iconCardGrid}>
        <IconCard Icon={Icon} onClick={handleClick} />
      </Grid>
      <Grid item xs md={12}>
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classes.description}
        >
          {description}
        </Typography>
        <div className={classes.blockLink}>
          <a
            href={link}
            className={classes.link}
            onMouseOver={handlerHover}
            onMouseOut={handlerHover}
          >
            {t('see more')}
          </a>
          <span
            className={hover ? classes.linkChevronHover : classes.linkChevron}
          >
            <ArrowForwardIosIcon
              sx={{
                paddingTop: { xs: '0.1rem', sm: '0.1rem', md: '0.3rem' },
                fontSize: { xs: '16px', sm: '16px', md: '20px' },
              }}
            />
          </span>
        </div>
      </Grid>
    </Grid>
  );
}

MenuItemComponent.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  description: PropTypes.string.isRequired,
};

export default MenuItemComponent;
