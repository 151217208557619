import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  center: {
    margin: '2em',
    [theme.breakpoints.up('md')]: {
      margin: '2.5em 4em 0px calc(56px + 1em)',
      padding: '0px 0px 0px 2em',
    },
  },
  headerContainer: {
    paddingBottom: '2em',
  },
}));

export default useStyles;
