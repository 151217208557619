import React, { useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  FileDownloadOutlined,
  OpenInNewOffRounded,
  OpenInNewRounded,
  KeyboardReturnRounded,
  BookmarksOutlined,
} from '@mui/icons-material/';
import IconCard from '../../components/IconCard/IconCard';
import useStyles from './WelcomeBooklet.style';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import AccueilPDFLocal from '../../assets/LivretAccueilCampus.pdf';
import MobileNavBar from '../../components/MobileNavBar/MobileNavBar';

function WelcomeBooklet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [openPDF, setOpenPDF] = useState(true);
  const user = useSelector((state) => state.user);

  // lien one drive possédé par DAVID PERRAI
  const AccueilPDF =
    'https://onedrive.live.com/embed?cid=A3EDF9C8BD52DD60&resid=A3EDF9C8BD52DD60%21570&authkey=AIFPjTKDY-CQRf4&em=2';

  const onDownload = () => {
    const link = document.createElement('a');
    // Lien à changer plus tard, qui pointera sur l'API ressources/sites
    link.download = AccueilPDFLocal;
    link.href = AccueilPDFLocal;
    link.click();
  };

  return (
    <>
      <main>
        <Box className={classes.center}>
          <Grid container spacing={2} className={classes.background}>
            <UseLocationMenu />
            <Grid
              item
              xs={12}
              md={4}
              className={!openPDF ? classes.openPDF : ''}
            >
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.leftContainer}
                  alignItems="baseline"
                  wrap="nowrap"
                  gap={2}
                >
                  <IconCard Icon={BookmarksOutlined} card="cardSmall" />
                  <Typography
                    variant="h5"
                    className={classes.title}
                    sx={{
                      fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.5rem' },
                      fontWeight: 'bold',
                    }}
                  >
                    {t('welcome_booklet.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.leftContainer}
                  gap={2}
                >
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography className={classes.description}>
                      {t('welcome_booklet.description', {
                        name: user?.campus?.name,
                      })}
                    </Typography>
                    <Button
                      sx={{
                        paddingX: { xs: 0, sm: 0, md: '1em' },
                        backgroundColor: '#767676',
                        color: 'white',
                        marginTop: { xs: 0, sm: 0, md: '1em' },
                        textTransform: 'uppercase',
                      }}
                      className={classes.download}
                      variant="contained"
                      onClick={onDownload}
                    >
                      {t('welcome_booklet.download')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                textAlign: { xs: 'center', sm: 'center', md: 'left' },
              }}
            >
              {/* <object
                className={!openPDF ? classes.pdfViewerOn : classes.pdfViewer}
                // Lien à changer plus tard, qui pointera sur l'API ressources/sites
                data={AccueilPDF}
                type="application/pdf"
              > */}
              <iframe
                // Lien à changer plus tard, qui pointera sur l'API ressources/sites
                src={`${AccueilPDF}`}
                title="Livret d'accueil campus"
                height="500vh"
                width="40%"
                frameBorder="0"
                className={!openPDF ? classes.iframeOn : classes.iframe}
              >
                <p>Ce navigateur ne supporte pas les PDFs !</p>
              </iframe>
              {/* </object> */}
            </Grid>
          </Grid>
          <MobileNavBar
            // first icon
            onClickFirst={() => navigate(-1)}
            cardFirst="cardSmall"
            IconFirst={KeyboardReturnRounded}
            // second icon
            onClickSecond={() => setOpenPDF(!openPDF)}
            cardSecond="cardSmallRondedBlue"
            cardContentSecond="cardContentSmallRondedBlue"
            sxCardSecond={{ borderRadius: '50px' }}
            IconSecond={() =>
              !openPDF ? (
                <OpenInNewOffRounded color="primary" />
              ) : (
                <OpenInNewRounded color="primary" />
              )
            }
            // third icon
            onClickThird={onDownload}
            cardThird="cardSmall"
            cardContentThird="cardContentSmallButton"
            IconThird={() => <FileDownloadOutlined color="primary" />}
          />
        </Box>
      </main>
    </>
  );
}

export default WelcomeBooklet;
