import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import IconCard from '../IconCard/IconCard';
import useStyles from './MobileNavBar.style';

export default function MobileNavBar({
  onClickFirst,
  onClickSecond,
  onClickThird,
  IconFirst,
  IconSecond,
  IconThird,
  cardFirst,
  cardSecond,
  cardThird,
  cardContentSecond,
  cardContentThird,
  sxCardSecond,
}) {
  const classes = useStyles();

  return (
    <Grid container wrap="nowrap" gap={9} className={classes.main}>
      <Grid item>
        <IconCard Icon={IconFirst} onClick={onClickFirst} card={cardFirst} />
      </Grid>
      {IconSecond && (
        <Grid item>
          <IconCard
            Icon={IconSecond}
            onClick={onClickSecond}
            card={cardSecond}
            cardContent={cardContentSecond}
            sxCard={sxCardSecond}
          />
        </Grid>
      )}
      {IconThird && (
        <Grid item>
          <IconCard
            Icon={IconThird}
            onClick={onClickThird}
            card={cardThird}
            cardContent={cardContentThird}
          />
        </Grid>
      )}
    </Grid>
  );
}

MobileNavBar.defaultProps = {
  onClickSecond: () => null,
  onClickThird: () => null,
  IconSecond: null,
  IconThird: null,
  cardSecond: '',
  cardThird: '',
  cardContentSecond: '',
  cardContentThird: '',
  sxCardSecond: null,
};

MobileNavBar.propTypes = {
  onClickFirst: PropTypes.func.isRequired,
  onClickSecond: PropTypes.func,
  onClickThird: PropTypes.func,
  IconFirst: PropTypes.shape().isRequired,
  IconSecond: PropTypes.shape(),
  IconThird: PropTypes.shape(),
  cardFirst: PropTypes.string.isRequired,
  cardSecond: PropTypes.string,
  cardThird: PropTypes.string,
  cardContentSecond: PropTypes.string,
  cardContentThird: PropTypes.string,
  sxCardSecond: PropTypes.shape(),
};
