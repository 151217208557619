import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import DesktopCalendar from './DesktopCalendar/DesktopCalendar';
import MobileCalendar from './MobileCalendar/MobileCalendar';
import Items from '../../services/items.service';

export default function Agenda() {
  const user = useSelector((state) => state.user);

  const [items, setItems] = useState([]);
  const [date, setDate] = useState(new Date());

  const startDate = user?.currentParticipation?.session?.startDate;
  const endDate = user?.currentParticipation?.session?.endDate;

  const dateIsBeforeSession = () => {
    const today = moment();
    if (today.isBefore(startDate)) {
      return new Date(startDate) || new Date();
    }
    return new Date();
  };

  const findItems = () => {
    Items.findByExternalSiteId(user.octopusUserId, startDate, endDate)
      .then((res) => {
        setItems(res);
        return new Date(res[0].startDate) < new Date()
          ? setDate(dateIsBeforeSession())
          : setDate(new Date(res[0].startDate));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (startDate && endDate) findItems();
  }, []);

  return (
    <>
      {window.innerWidth > 900 ? (
        <DesktopCalendar
          dateIsBeforeSession={dateIsBeforeSession}
          items={items}
        />
      ) : (
        <MobileCalendar
          dateIsBeforeSession={dateIsBeforeSession}
          items={items}
          onDate={date}
        />
      )}
    </>
  );
}
