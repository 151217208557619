import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Unauthorized from '../Unauthorized/Unauthorized';

function PrivateRoute({ children }) {
  const user = useSelector((state) => state.user);

  return user?.isAuthenticated() ? children : <Unauthorized />;
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PrivateRoute;
