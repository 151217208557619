import { PublicClientApplication } from '@azure/msal-browser';
import { ROOT_URL } from 'navigation/RoutesConstants';

export const MicrosoftAuthConfig = {
  auth: {
    clientId: process.env.REACT_APP_OMNIAUTH_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OMNIAUTH_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/common',
    postLogoutRedirectUri: ROOT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const MicrosoftClient = new PublicClientApplication(MicrosoftAuthConfig);

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};
