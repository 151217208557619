import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import moment from 'moment';

import useStyles from './SmallMobileCalendar.style';
import CourseCard from '../CourseCard/CourseCard';

export default function SmallMobileCalendar({ daySelected, events }) {
  const classes = useStyles();
  const oneDay = moment(daySelected, 'DD-MM-YYYY').add(1, 'd').format();
  const twoDays = moment(daySelected, 'DD-MM-YYYY').add(2, 'd').format();

  const daysList = [daySelected, oneDay, twoDays];
  const displayDays = () => {
    return daysList.map((day) => {
      let hasEvents = false;
      return (
        <Grid
          container
          direction="row-reverse"
          className={classes.dayContainer}
        >
          <Grid item xs={10} className={classes.cardsContainer}>
            <Grid container>
              {events.map((event) => {
                if (
                  new Date(event.startDate).toLocaleDateString('fr-FR') ===
                  moment(day).format('DD/MM/YYYY')
                ) {
                  hasEvents = true;
                  return (
                    <div className={classes.cardContainer}>
                      <CourseCard event={event} />
                    </div>
                  );
                }
                return null;
              })}
              {!hasEvents && <hr className={classes.horizontalLine} />}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h6"
              className={
                hasEvents ? classes.dayWithEvent : classes.dayWithoutEvent
              }
              sx={{ fontWeight: 700 }}
            >
              {moment(day, 'YYYY-MM-DD HH:mm:ss').format('ddd')}
            </Typography>
            <Typography
              variant="h6"
              className={
                hasEvents ? classes.dayWithEvent : classes.dayWithoutEvent
              }
            >
              {moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD')}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  return <>{displayDays()}</>;
}

SmallMobileCalendar.propTypes = {
  daySelected: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
