import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #0188ce',
    borderRadius: '5px',
    opacity: 1,
    color: '#0188ce',
    padding: '0.2em 0.5em',
    height: '100%',
  },
  date: {
    opacity: 0.5,
    color: '#000000',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
  gridTrainer: {
    marginTop: '0.4em',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'end',
      position: 'absolute',
      bottom: '0px',
      right: '0px',
      padding: '0.2em 0.5em',
    },
  },
  trainer: {
    color: '#000000',
    fontStyle: 'oblique',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
}));

export default useStyles;
