import React from 'react';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './OutlinedDiv.style';

const OutlinedDiv = ({ id, label, children, className }) => {
  const classes = useStyles();

  return (
    <div className={[className, classes.root].join(' ')}>
      <InputLabel
        htmlFor={id}
        variant="outlined"
        className={classes.inputLabel}
        shrink
      >
        {label}
      </InputLabel>
      <div className={classes.contentWrapper}>
        <div id={id} className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

OutlinedDiv.propTypes = {
  id: PropTypes.number,
  children: PropTypes.objectOf(),
  label: PropTypes.string,
  className: PropTypes.string,
};

OutlinedDiv.defaultProps = {
  id: '',
  children: '',
  label: '',
  className: '',
};

export default OutlinedDiv;
