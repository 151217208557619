import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '8px ',
  },
  contentWrapper: {
    position: 'relative',
  },
  content: {
    padding: '18.5px 14px 0',
    borderRadius: '10px',
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    color: 'black',
  },
  inputLabel: {
    position: 'absolute !important',
    left: '0px !important',
    top: '0px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    backgroundColor: 'white',
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 'bold !important',
  },
  notchedOutline: {
    borderRadius: '4px',
  },
}));

export default useStyles;
