import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    color: '#3C3C3B',
    paddingTop: '1.1em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 700,
    fontSize: '90%',
    minHeight: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  today: {
    color: '#3C3C3B',
  },
  restOfWeek: {
    opacity: 0.3,
  },
}));

export default useStyles;
