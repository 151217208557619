import { gql } from '@apollo/client';
import { GraphqlQuery, GraphqlMutate } from '../config/apolloClient';

const USERS = gql`
  query userAll {
    users {
      id
      firstname
      lastname
      cp
      email
    }
  }
`;
const ME = gql`
  query me {
    me {
      id
      cp
      firstname
      lastname
      email
      octopusUserId
      institution {
        id
        name
      }
      role {
        id
        name
      }
      campus {
        id
        name
      }
      currentParticipation {
        arrival
        departure
        isHalfBoard
        session {
          id
          name
          startDate
          endDate
        }
        hotelName
        hotelRoomNumber
        isHalfBoard
      }
      userAttributes {
        id
        name
        value
      }
    }
  }
`;
const COMMUNICATE_ITS_ARRIVAL = gql`
  mutation updateArrival(
    $id: Int!
    $phone: String
    $arrival: String!
    $ending: String!
    $sessionId: Int!
    $octopusUserId: Int!
    $isHalfBoard: Boolean!
  ) {
    communicateItsArrival(
      arrivalUserInput: {
        id: $id
        phone: $phone
        arrivalDate: $arrival
        endingDate: $ending
        sessionId: $sessionId
        octopusUserId: $octopusUserId
        isHalfBoard: $isHalfBoard
      }
    ) {
      octopusUserId
    }
  }
`;

export default class User {
  static all() {
    return GraphqlQuery(USERS)
      .then((result) => result.users)
      .catch((e) => e);
  }

  static me() {
    return GraphqlQuery(ME)
      .then((result) => result.me)
      .catch((e) => e);
  }

  static itsArrival(input) {
    return GraphqlMutate(COMMUNICATE_ITS_ARRIVAL, input).then((result) => {
      if (result?.errors?.length > 0) {
        throw new Error('Impossible de mettre à jour les informations');
      }
      return result.communicateItsArrival;
    });
  }
}
