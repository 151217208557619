import { gql } from '@apollo/client';
import { GraphqlQuery } from '../config/apolloClient';

import RequestAccessToken from './requestAccessToken.service';

const FormData = require('form-data');
const axios = require('axios');

const SEARCH_SITE_FOR_OCTOPUS_CAMPUS_ID = gql`
  query searchSiteForOctopusCampusId($octopusCampusId: Float!) {
    searchSiteForOctopusCampusId(octopusCampusId: $octopusCampusId) {
      id
      buildings {
        id
        name
        floors {
          id
          name
        }
      }
    }
  }
`;

export default class Ressource {
  static searchSiteForOctopusCampusId(octopusCampusId) {
    return GraphqlQuery(SEARCH_SITE_FOR_OCTOPUS_CAMPUS_ID, {
      octopusCampusId,
    })
      .then((result) => result?.searchSiteForOctopusCampusId)
      .catch((e) => e);
  }

  static uploadWeekMenu(file) {
    const data = new FormData();
    let config;
    data.append(
      'operations',
      '{ "query":" mutation uploadWeekMenuFile($file: Upload!) {    uploadWeekMenuFile(file: $file)  }", "variables": { "file": null } }'
    );
    data.append('map', '{ "0": ["variables.file"] }');
    data.append('0', file);
    return RequestAccessToken((response) => {
      const { accessToken } = response;
      config = {
        method: 'post',
        url: process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_GRAPHQL_ENPOINT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data,
      };
    }).then(() =>
      axios(config)
        .then((response) => response.data.data.uploadWeekMenuFile)
        .catch((error) => {
          throw error;
        })
    );
  }
}
