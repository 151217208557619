import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dayWithEvent: {
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dayWithoutEvent: {
    color: '#707070',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dayContainer: {
    padding: '0 2em',
    marginBottom: '0.5em',
    height: '100%',
    display: 'flex',
  },
  cardsContainer: {
    paddingLeft: '1em',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0.5em',
    },
  },
  cardContainer: {
    marginBottom: '0.5em',
    width: '100%',
  },
  horizontalLine: {
    width: '100%',
    height: '2px',
    border: 'none',
    marginTop: '1.5em',
    color: '#e6e6e6',
    backgroundColor: '#e6e6e6',
  },
}));

export default useStyles;
