/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function ProfilePicture({ userFullName, size }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        ...size,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <Avatar
      alt={t('header.avatar_alt')}
      src={user.photoUrl}
      {...stringAvatar(userFullName)}
    />
  );
}

ProfilePicture.propTypes = {
  userFullName: PropTypes.string.isRequired,
  size: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    fontSize: PropTypes.string,
  }),
};
