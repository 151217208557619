/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import writeXlsxFile from 'write-excel-file';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ExportToExcelButton({ ResidentData }) {
  const { t } = useTranslation();

  const schema = [
    {
      column: t('residents_list.array.cp'),
      type: String,
      value: (resident) => resident.cp,
      width: 10,
      align: 'right',
    },
    {
      column: t('residents_list.array.lastname'),
      type: String,
      value: (resident) => resident.lastname,
      width: 13,
      align: 'right',
    },
    {
      column: t('residents_list.array.firstname'),
      type: String,
      value: (resident) => resident.firstname,
      width: 13,
      align: 'right',
    },
    {
      column: t('residents_list.array.status'),
      type: String,
      value: (resident) => resident.status,
      width: 10,
      align: 'right',
    },
    {
      column: t('residents_list.array.arrival_date'),
      type: String,
      value: (resident) => resident.arrival,
      width: 30,
      align: 'right',
    },
    {
      column: t('residents_list.array.departure_date'),
      type: String,
      value: (resident) => resident.departure,
      width: 25,
      align: 'right',
    },
    {
      column: t('residents_list.array.mail'),
      type: String,
      value: (resident) => resident.email,
      width: 20,
      align: 'right',
    },
    {
      column: t('residents_list.array.phone_number'),
      type: String,
      value: (resident) => resident.phoneNumber,
      width: 20,
      align: 'right',
    },
    {
      column: t('residents_list.array.room_number'),
      type: String,
      value: (resident) => resident.hotelRoomNumber,
      width: 20,
      align: 'right',
    },
    {
      column: t('residents_list.array.hotel_name'),
      type: String,
      value: (resident) => resident.hotelName,
      width: 60,
      align: 'right',
    },
  ];

  const exportToExcel = async () => {
    const downloadDate = moment().format('DD-MM-YYYY');
    await writeXlsxFile(ResidentData, {
      schema,
      headerStyle: {
        backgroundColor: '#eeeeee',
        fontWeight: 'bold',
        align: 'center',
      },
      fileName: `${t('residents_list.title')}-${downloadDate}.xlsx`,
      sheet: t('residents_list.title'),
    });
  };
  return (
    <Button
      disabled={ResidentData.length === 0}
      variant="contained"
      sx={{
        width: 200,
        paddingX: '2em',
        backgroundColor: '#4D4F53',
        color: 'white',
        textTransform: 'uppercase',
        ':hover': {
          bgcolor: '#000',
        },
        marginBottom: '1em',
        fontSize: '12px',
      }}
      onClick={exportToExcel}
    >
      {t('residents_list.download')}
    </Button>
  );
}

ExportToExcelButton.propTypes = {
  ResidentData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      cp: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      arrival: PropTypes.string.isRequired,
      departure: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      hotelRoomNumber: PropTypes.string.isRequired,
      hotelName: PropTypes.string.isRequired,
    })
  ).isRequired,
};
