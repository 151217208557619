import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  inputText: {
    width: '100%',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& input': {
      padding: '9px',
    },
    '& textarea': {
      padding: '9px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  label: {
    color: 'black !important',
    fontWeight: 'bold !important',
  },
  input: {
    border: 'none',
    background: 'red',
  },
}));

export default useStyles;
