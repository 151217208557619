import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: '1.5em',
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: 1,
    margin: '1.5em',
  },
  main: {
    color: '#0188ce',
    height: '100%',
    width: '100%',
  },
  uploadButton: {
    backgroundColor: '#FFF',
    fontSize: '14px',
    color: '#0188ce',
    border: '2px solid #0188ce',
    borderRadius: '5px',
    padding: '0.59375rem 1.875rem',
    cursor: 'pointer',
  },
  error: {
    color: '#FF0000',
    margin: '1em',
    background: '#ffefef',
  },
  cardContent: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'fit-content',
    },
  },
}));

export default useStyles;
