import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import useStyles from './IconCard.style';

function IconCard({ Icon, card, cardContent, img, sxCard, onClick }) {
  const classes = useStyles();

  return (
    <Card className={classes[card]} sx={sxCard} onClick={onClick}>
      <CardContent className={classes[cardContent]}>
        <Icon className={classes[img]} />
      </CardContent>
    </Card>
  );
}

IconCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  card: PropTypes.string,
  cardContent: PropTypes.string,
  img: PropTypes.string,
  sxCard: PropTypes.shape(),
  onClick: PropTypes.func,
};

IconCard.defaultProps = {
  card: 'card',
  cardContent: 'cardContent',
  img: 'img',
  sxCard: null,
  onClick: () => null,
};

export default IconCard;
