import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  center: {
    flexGrow: 1,
    marginLeft: `calc(56px + 1em)`,
    padding: 0,
    marginTop: '1.5em',
  },
  gridCalendar: {
    height: '80vh',
    padding: '0px 50px',
    [theme.breakpoints.down('xl')]: {
      height: '150vh',
    },
  },
}));

export default useStyles;
