import { gql } from '@apollo/client';
import { GraphqlMutate, GraphqlQuery } from '../config/apolloClient';

const ANOMALIES_BY_SITE = gql`
  query anomaliesBySite($id: Int!, $userId: Int!) {
    anomaliesBySite(id: $id, userId: $userId) {
      id
      status {
        id
        name
      }
      createdAt
      updatedAt
      type {
        id
        name
        category {
          id
          name
        }
      }
      creatorUserId
      floorRessourceId

      comment
    }
  }
`;

const CREATE_ANOMALY = gql`
  mutation createAnomaly($input: CreateAnomalyInput!) {
    createAnomaly(createAnomalyInput: $input) {
      id
      status {
        id
        name
      }
      createdAt
      updatedAt
      type {
        id
        name
        category {
          id
          name
        }
      }
      creatorUserId
      floorRessourceId
      comment
    }
  }
`;

const GET_CATEGORIES_FOR_SITE_RESSOURCE_ID = gql`
  query getCategoriesForSiteRessourceId($siteRessourceId: Float!) {
    getCategoriesForSiteRessourceId(siteRessourceId: $siteRessourceId) {
      id
      name
      types {
        id
        name
        criticality {
          id
          name
        }
      }
    }
  }
`;

const DELETE_ANOMALY = gql`
  mutation deleteAnomaly($id: Int!) {
    deleteAnomaly(id: $id) {
      id
    }
  }
`;

export default class Anomaly {
  static create(input) {
    return GraphqlMutate(CREATE_ANOMALY, {
      input,
    })
      .then((result) => result.createAnomaly)
      .catch((e) => e);
  }

  static getCategoriesForSiteRessourceId(siteRessourceId) {
    return GraphqlQuery(GET_CATEGORIES_FOR_SITE_RESSOURCE_ID, {
      siteRessourceId,
    })
      .then((result) => result.getCategoriesForSiteRessourceId)
      .catch((e) => e);
  }

  static bySite(input) {
    return GraphqlQuery(ANOMALIES_BY_SITE, input)
      .then((result) => result.anomaliesBySite)
      .catch((e) => e);
  }

  static delete(id) {
    return GraphqlMutate(DELETE_ANOMALY, {
      id,
    })
      .then((result) => result.deleteAnomaly)
      .catch((e) => e);
  }
}
