import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import useStyles from './LabelCard.style';

function LabelCard({ label, link }) {
  const classes = useStyles();
  const handleClick = () => {
    window.open(link);
  };

  return (
    <Card className={classes.card} onClick={handleClick}>
      <CardContent
        className={classes.cardContent}
        sx={{
          verticalAlign: 'middle',
          fontWeight: 600,
          padding: {
            md: '25% 15% 25% 15%',
            xs: '15% 15% 15% 15%',
          },
          '&:last-child': {
            paddingBottom: {
              xs: '15%',
              md: '25%',
            },
          },
        }}
      >
        {label}
      </CardContent>
    </Card>
  );
}

LabelCard.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default LabelCard;
