import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '10em',
    aspectRatio: 3 / 2,
    textAlign: 'center',
    margin: '1em 0 1em 0',
    cursor: 'pointer',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      aspectRatio: 'auto',
      width: '90%',
      height: 'fit-content',
      margin: '1em auto 1em auto',
    },
  },
  cardContent: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
    padding: '25% 15%',
    [theme.breakpoints.down('md')]: {
      height: 'fit-content',
    },
  },
}));

export default useStyles;
