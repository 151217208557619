import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      visibility: 'collapse',
      display: 'none',
      paddingTop: '0px',
    },
  },
  text: {
    letterSpacing: '0px',
    color: '#0088CE',
    opacity: 3,
    textAlign: 'left',
    fontWeight: '900',
    [theme.breakpoints.down('md')]: {
      visibility: 'collapse',
      display: 'none',
    },
  },
}));

export default useStyles;
