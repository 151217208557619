import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
    minWidth: '20%',
    heigth: '100vh',
    padding: '5% 10% 5% 0',
    textAlign: 'left',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: '5% 0 5% 0',
    },
  },
  description: {
    textAlign: 'left',
  },
  linkChevronHover: {
    paddingLeft: '0.5rem',
    color: '#0188CE',
  },
  linkChevron: {
    paddingLeft: '0.1rem',
    color: '#0188CE',
  },
  link: {
    fontSize: '0.8rem',
    color: '#0188CE',
  },
  blockLink: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  iconCardGrid: {
    paddingBottom: 10,
  },
}));

export default useStyles;
