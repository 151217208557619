import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Calendar from 'react-calendar';
import './Calendar.css';
import useStyles from './MobileCalendar.style';
import SmallMobileCalendar from '../SmallMobileCalendar/SmallMobileCalendar';

export default function MobileCalendar({ dateIsBeforeSession, items, onDate }) {
  const classes = useStyles();
  const [value, onChange] = useState(onDate);

  const [daySelected, setDaySelected] = useState(dateIsBeforeSession);

  const setClass = (date) => {
    let cssClasses =
      'react-calendar__month-view__days react-calendar__month-view__weekdays__weekday';
    const dateobj =
      items &&
      items.find((x) => {
        return (
          date.getDay() === new Date(x.startDate).getDay() &&
          date.getMonth() === new Date(x.startDate).getMonth() &&
          date.getDate() === new Date(x.startDate).getDate()
        );
      });
    if (date.getDate() < 10) cssClasses += ' singleDigitDay';
    else cssClasses += ' doubleDigitDay';
    return dateobj ? `${cssClasses} lessonDay` : `${cssClasses}`;
  };

  return (
    <>
      <main>
        <Box className={classes.center}>
          <Calendar
            onChange={onChange}
            value={value}
            defaultValue={dateIsBeforeSession.toString()}
            view="month"
            minDetail="month"
            next2Label={null}
            prev2Label={null}
            onClickDay={(date) => {
              setDaySelected(date);
            }}
            tileClassName={({ date }) => setClass(date)}
          />
        </Box>
        {daySelected && (
          <SmallMobileCalendar daySelected={daySelected} events={items} />
        )}
      </main>
    </>
  );
}

MobileCalendar.propTypes = {
  dateIsBeforeSession: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDate: PropTypes.instanceOf(Date).isRequired,
};
