import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/MicrosoftAuthConfig';
import { useTranslation } from 'react-i18next';

export default function ConnexionButton({ mobile = false }) {
  const { instance: msalInstance } = useMsal();
  const { t } = useTranslation();

  const handleLogin = () => {
    msalInstance.loginRedirect(loginRequest).catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
    });
  };

  const screenStyle = mobile
    ? {
        marginLeft: '0.2em',
        padding: '0.3em 0.8em',
        textTransform: 'uppercase',
        fontSize: '0.8em',
      }
    : {
        marginLeft: '1.5em',
        paddingX: '1em',
        textTransform: 'uppercase',
      };

  return (
    <Button
      sx={screenStyle}
      color="primary"
      variant="contained"
      onClick={handleLogin}
      data-testid={`${
        mobile ? 'connexionButtonMobile' : 'connexionButtonDesktop'
      }`}
    >
      <PermIdentityOutlinedIcon />
      &nbsp;{t('header.bouton_connexion')}
    </Button>
  );
}

ConnexionButton.propTypes = {
  mobile: PropTypes.bool,
};

ConnexionButton.defaultProps = {
  mobile: false,
};
