import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from './LocationCard.style';
import { BegleHome } from '../../assets';

function LocationCard({ label, isActive }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card
      className={isActive ? classes.cardIsActive : classes.card}
      elevation={isActive ? 2 : 0}
    >
      <CardMedia
        component="img"
        image={BegleHome}
        alt={t('public_home.img_campus_alt')}
        className={classes.cardMedia}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            fontWeight: 600,
            marginBottom: 0,
            fontSize: {
              xs: 16,
              sm: 18,
              md: 21,
              lg: 25,
            },
          }}
        >
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}

LocationCard.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default LocationCard;
