import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    color: '#0088ce',
    fontFamily: ['Avenir', 'Arial'].join(','),
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& div': {
      border: 'none !important',
    },
    '& svg': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      borderRadius: '3px',
    },
  },
}));

export default useStyles;
