import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  center: {
    flexGrow: 1,
    margin: '1em 2em',
  },
}));

export default useStyles;
