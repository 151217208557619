export default function ValidationForm(setErrors, sendNext = () => null) {
  // get all displayed inputs in DOM
  const inputTextfields = document.querySelectorAll('input');
  // if a type of input is problematic, modify querySelector: 'input:not([type="checkbox"])'

  // in case of there is no inputs we can go to the next page
  const arrayValidation = [true];

  inputTextfields.forEach((input) => {
    if (input.required && input.value) {
      arrayValidation.push(true);
    }
    if (input.required && !input.value) {
      arrayValidation.push(false);
    }
    if (
      input.pattern === '[0-9]*' &&
      input.value.length > 0 &&
      input.value.length < 10
    ) {
      arrayValidation.push(false);
    }
  });

  const areAllElementTrue = (currentValue) => currentValue === true;

  // check if all inputs are valid, set data to form if any, and go to next step
  if (arrayValidation.every(areAllElementTrue)) {
    return sendNext();
  }

  setErrors(true);
}
